import React, {useState} from "react";
import { StyleSheet, View, FlatList, TouchableOpacity } from "react-native";
import { MaterialCommunityIcons } from '@expo/vector-icons';

import colors from "../../config/colors";
import Screen from "../../components/Screen";
import Text from "../../components/Text";


function AcademyScreen({ navigation }) {
  
  return (
    <Screen style={styles.screen}>
      <View style={styles.view}>
        <Text style={styles.header}>Unsere Academy</Text>
        <Text>coming soon...</Text>
      </View>
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    padding: 10,
    backgroundColor: colors.light,
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 5,
  },
  link: {
    color: 'blue',
    textDecorationLine: 'underline',
    marginBottom: 5,
  },
  view: {
    paddingBottom: 30,
  },
});

export default AcademyScreen;

