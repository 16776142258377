import React, { useState, useEffect } from "react";
import { FlatList, StyleSheet, ActivityIndicator, View } from "react-native";
import axios from 'axios';
import { useIsFocused } from '@react-navigation/native';

import { baseURL } from "../api/client";
import useAuth from "../auth/useAuth";
import Text from "../components/Text";
import Screen from "../components/Screen";
import {
  ListItem,
  ListItemDeleteAction,
  ListItemSeparator,
} from "../components/lists";
import colors from "../config/colors";


function MessagesScreen() {
  const { user } = useAuth();
  const isFocused = useIsFocused();
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  
  useEffect(() => {
    const fetchNotifications = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${baseURL}/notifications/getNotifications/${user.userID}`);
        setNotifications(response.data);
        } catch (error) {
          console.error("Fehler beim Abrufen der Themen:", error);
        }
        setIsLoading(false);
    };
    const patchNotifications = async () => {
      try {
        const response = await axios.patch(`${baseURL}/notifications/patchNotifications/${user.userID}`);
      } catch (error) {
        console.error("Fehler beim Abrufen der Themen:", error);
      }
    };
    if(isFocused){
      fetchNotifications();
      patchNotifications();
    }
  }, [isFocused]);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${baseURL}/notifications/getNotifications/${user.userID}`);
      setNotifications(response.data);
      } catch (error) {
        console.error("Fehler beim Abrufen der Themen:", error);
      }
  };

  const handleDelete = async (item) => {
    try {
      let notificationID=item.notificationID;
      const response = await axios.patch(`${baseURL}/notifications/deleteNotification/${notificationID}`);
      fetchNotifications();
    } catch (error) {
      console.error("Fehler beim Abrufen der Themen:", error);
    }
  };
  const renderNoNotificationsMessage = () => (
    <View style={styles.centeredMessage}>
      <Text style={styles.messageText}>Aktuell hast du keine Nachrichten.</Text>
      <Text style={styles.messageText}>Es scheint gerade ruhig zu sein...</Text>
    </View>
  );

  const onRefresh = () => {
    setRefreshing(true);
    fetchNotifications().then(() => {
      setRefreshing(false);
    });
  };

  return (
    <Screen>
      {isLoading ? (
        // Zeige den Loader an, wenn Daten geladen werden
        <View style={styles.loaderContainer}>
          <ActivityIndicator size="large" color={colors.primary} />
        </View>
      ) : (
        <>
          {notifications.length > 0 ? (
          <FlatList
            data={notifications}
            keyExtractor={(notifications) => notifications.notificationID.toString()}
            renderItem={({ item }) => (
              <ListItem
                title={item.notificationTitle}
                subTitle={item.notificationContent}
                additionalInfo={`Von: ${item.notificationFrom}`}
                renderRightActions={() => (
                  <ListItemDeleteAction onPress={() => handleDelete(item)} />
                )}
              />
            )}
            ItemSeparatorComponent={ListItemSeparator}
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
          ) : (
            renderNoNotificationsMessage()
          )}
        </>
      )}
    </Screen>
  );
}


const styles = StyleSheet.create({
  centeredMessage: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  messageText: {
    fontSize: 18,
    textAlign: 'center',
    color: 'grey',
    marginBottom: 10,
  },});

export default MessagesScreen;