import React from "react";
import { useFormikContext } from "formik";

import Button from "../ButtonBig";
import colors from "../../config/colors";

function SubmitButton({ title,color = "success" }) {
  const { handleSubmit } = useFormikContext();

  return <Button title={title} onPress={handleSubmit} style={{ backgroundColor: colors[color] }} />;
}

export default SubmitButton;
