// In Ihrer TextInput.js Datei
import React from 'react';
import { TextInput as RNTextInput, StyleSheet, View,TouchableOpacity } from 'react-native';

function TextInput({ multiline, numberOfLines, ...otherProps }) {
  const inputRef = React.useRef(null);

  return (
    <View style={styles.container}>
      <TouchableOpacity
        activeOpacity={1}
        onPressIn={() => console.log('Press In')}
        onPressOut={() => console.log('Press Out')}
      >
        <RNTextInput
          ref={inputRef}
          style={multiline ? [styles.textArea, {height: numberOfLines * 24}] : styles.textInput}
          multiline={multiline}
          numberOfLines={numberOfLines}
          {...otherProps}
        />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%', // sorgt dafür, dass der Container die volle Breite einnimmt
    borderRadius: 5, // abgerundete Ecken
    overflow: 'hidden', // stellt sicher, dass das TextInput die abgerundeten Ecken des Containers respektiert
    marginBottom: 20,
  },
  textInput: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    padding: 10,
  },
  textArea: {
    borderColor: 'gray',
    borderWidth: 1,
    padding: 10,
    textAlignVertical: 'top',  // sorgt dafür, dass der Text oben startet
  },
});

export default TextInput;
