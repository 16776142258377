import React from "react";
import { StyleSheet, View, FlatList, Linking } from "react-native";

import { ListItem, ListItemSeparator } from "../../components/lists";
import colors from "../../config/colors";
import Icon from "../../components/Icon";
import routes from "../../navigation/routes";
import Screen from "../../components/Screen";
import useAuth from "../../auth/useAuth";

const menuItems = [
  {
    title: "Impressum",
    icon: {
      name: "note-text-outline",
      backgroundColor: colors.info,
    },
    targetScreen: "https://specialsitter.de/impressum",
  },
  {
    title: "Datenschutz",
    icon: {
      name: "note",
      backgroundColor: colors.info,
    },
    targetScreen: "https://specialsitter.de/datenschutz",
  },
  {
    title: "AGB",
    icon: {
      name: "note-outline",
      backgroundColor: colors.info,
    },
    targetScreen: "https://specialsitter.de/agb",
  },
];

function LegalsScreen({ navigation }) {
  const { user, logOut } = useAuth();

  const handlePress = async (url) => {
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url);
    } else {
      console.log(`Don't know how to open this URL: ${url}`);
    }
  };

  
  return (
    <Screen style={styles.screen}>
      <View style={styles.container}>
        <FlatList
          data={menuItems}
          keyExtractor={(menuItem) => menuItem.title}
          ItemSeparatorComponent={ListItemSeparator}
          renderItem={({ item }) => (
            <ListItem
              title={item.title}
              IconComponent={
                <Icon
                  name={item.icon.name}
                  backgroundColor={item.icon.backgroundColor}
                />
              }
              onPress={() => handlePress(item.targetScreen)}
            />
          )}
        />
      </View>
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    backgroundColor: colors.light,
  },
  container: {
    marginVertical: 20,
  },
});

export default LegalsScreen;
