import React from "react";
import { View, StyleSheet, Modal } from "react-native";
import LottieView from 'lottie-react-native';

function DoneScreen({ onDone, visible = false }) {

  return (
    <Modal visible={visible}>
      <View style={styles.container}>
        <LottieView
          autoPlay
          loop={false}
          onAnimationFinish={onDone}
          source={require("../assets/animations/done.json")}
          style={styles.animation}
        />
      </View> 
    </Modal>
  );
}

const styles = StyleSheet.create({
  animation: {
    flex: 1,
    width: 150,  // Stellen Sie sicher, dass diese Werte korrekt sind
    height: 150,  // Fügen Sie eine Höhe hinzu, falls benötigt
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{ translateX: -75 }, { translateY: -75 }], 
  },
});

export default DoneScreen;
