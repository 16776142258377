import React from "react";
import { StyleSheet, View, FlatList } from "react-native";

import { ListItem, ListItemSeparator } from "../../components/lists";
import Text from "../../components/Text";
import colors from "../../config/colors";
import Icon from "../../components/Icon";
import routes from "../../navigation/routes";
import Screen from "../../components/Screen";
import useAuth from "../../auth/useAuth";

const menuItems = [
  {
    title: "Hamburg",
    subTitle: "andere Infos?",
    icon: {
      name: "arrow-right",
      backgroundColor: colors.info,
    },
    targetScreen: routes.NETWORKINGDETAILSCREEN,
    threadTitle: "Hamburg",
  },
  {
    title: "Lübeck",
    subTitle: "andere Infos?",
    icon: {
      name: "arrow-right",
      backgroundColor: colors.info,
    },
    targetScreen: routes.NETWORKINGDETAILSCREEN,
    threadTitle: "Lübeck",
  },
  {
    title: "Berlin",
    subTitle: "andere Infos?",
    icon: {
      name: "arrow-right",
      backgroundColor: colors.info,
    },
    targetScreen: routes.NETWORKINGDETAILSCREEN,
    threadTitle: "Berlin",
  },
  {
    title: "Brandenburg",
    subTitle: "andere Infos?",
    icon: {
      name: "arrow-right",
      backgroundColor: colors.info,
    },
    targetScreen: routes.NETWORKINGDETAILSCREEN,
    threadTitle: "Brandenburg",
  },
  {
    title: "Dresden",
    subTitle: "andere Infos?",
    icon: {
      name: "arrow-right",
      backgroundColor: colors.info,
    },
    targetScreen: routes.NETWORKINGDETAILSCREEN,
    threadTitle: "Dresden",
  },
  {
    title: "Essen",
    subTitle: "andere Infos?",
    icon: {
      name: "arrow-right",
      backgroundColor: colors.info,
    },
    targetScreen: routes.NETWORKINGDETAILSCREEN,
    threadTitle: "Essen",
  },
  {
    title: "Saarland",
    subTitle: "andere Infos?",
    icon: {
      name: "arrow-right",
      backgroundColor: colors.info,
    },
    targetScreen: routes.NETWORKINGDETAILSCREEN,
    threadTitle: "Saarland",
  },
  {
    title: "Nürnberg",
    subTitle: "andere Infos?",
    icon: {
      name: "arrow-right",
      backgroundColor: colors.info,
    },
    targetScreen: routes.NETWORKINGDETAILSCREEN,
    threadTitle: "Nürnberg",
  },
  {
    title: "München",
    subTitle: "andere Infos?",
    icon: {
      name: "arrow-right",
      backgroundColor: colors.info,
    },
    targetScreen: routes.NETWORKINGDETAILSCREEN,
    threadTitle: "München",
  },
  {
    title: "Oberbayern",
    subTitle: "andere Infos?",
    icon: {
      name: "arrow-right",
      backgroundColor: colors.info,
    },
    targetScreen: routes.NETWORKINGDETAILSCREEN,
    threadTitle: "Oberbayern",
  },
  {
    title: "Niederbayern",
    subTitle: "andere Infos?",
    icon: {
      name: "arrow-right",
      backgroundColor: colors.info,
    },
    targetScreen: routes.NETWORKINGDETAILSCREEN,
    threadTitle: "Niederbayern",
  },
  {
    title: "Überregional",
    subTitle: "andere Infos?",
    icon: {
      name: "arrow-right",
      backgroundColor: colors.info,
    },
    targetScreen: routes.NETWORKINGDETAILSCREEN,
    threadTitle: "Überregional",
  },
];

function NetworkingScreen({navigation}) {
  const { user, logOut } = useAuth();

  return (
    <Screen style={styles.screen}>
      <FlatList
        data={menuItems}
        keyExtractor={(menuItem) => menuItem.title}
        ItemSeparatorComponent={ListItemSeparator}
        renderItem={({ item }) => (
          <ListItem
            title={item.title}
            subTitle={item.subTitle}
            subTitleStyle={styles.subTitleText} 
            IconComponent={<Icon name={item.icon.name} backgroundColor={item.icon.backgroundColor} />}
            onPress={() => navigation.navigate(item.targetScreen, { title: item.threadTitle })}
          />
        )}
      />
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    backgroundColor: colors.light,
  },
  container: {
    marginVertical: 20,
  },
  containerItem: {
    alignItems: "center",
    flexDirection: "row",
    padding: 15,
    backgroundColor: colors.white,
  },
  detailsContainer: {
    flex: 1,
    marginLeft: 10,
    justifyContent: "center",
  },
  image: {
    width: 70,
    height: 70,
    borderRadius: 35,
  },
  subTitleText: {
    fontSize: 14,
    color: colors.success,
  },
  title: {
    fontWeight: "500",
  },
});

export default NetworkingScreen;
