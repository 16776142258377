import React, { useRef, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity, FlatList } from "react-native";
import { MaterialCommunityIcons } from '@expo/vector-icons';

import colors from "../../config/colors";
import Screen from "../../components/Screen";
import Text from "../../components/Text";
import useAuth from "../../auth/useAuth";

function NetworkingDetailScreen({ route, navigation }) {
  const { user } = useAuth();
  

  return (
    <Screen style={styles.screen}>
      <View style={styles.threadMessage}>
        <Text style={styles.header}>Hier muss noch Inhalt hinein</Text>
        <Text style={styles.body}>Noch ein beispieltext...</Text>
      </View>
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    padding: 20,
    backgroundColor: colors.light,
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  body: {
    fontSize: 16,
    marginBottom: 10,
  },
  messageContainer: {
    marginBottom: 10,
    flexDirection: 'row',  // Zeilenrichtung, damit die Sprechblasen korrekt ausgerichtet werden
  },
  myMessageContainer: {
    justifyContent: 'flex-end',  // Eigene Nachrichten am rechten Rand ausrichten
  },
  otherMessageContainer: {
    justifyContent: 'flex-start',  // Andere Nachrichten am linken Rand ausrichten
  },
  myMessageBubble: {
    width: '80%',
    backgroundColor: colors.success,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: 10,
  },
  otherMessageBubble: {
    width: '80%',
    backgroundColor: colors.white,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: 10,
  },
  messageText: {
    fontSize: 16,
    color: colors.black,
  },
  myMessageText: {
    color: colors.white,
  },
  fab: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    backgroundColor: colors.primary,
    borderRadius: 50,
    width: 56,
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
  },
  threadMessage: {
    paddingBottom: 30,
  },
  myMessageSender: {
    fontSize: 12,
    color: colors.light,
  },
  otherMessageSender: {
    fontSize: 12,
    color: colors.primary,
  }
});

export default NetworkingDetailScreen;
