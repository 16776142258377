import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, Alert, ScrollView, KeyboardAvoidingView, Platform } from "react-native";
import { FontAwesome } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons'; // Stellen Sie sicher, dass Sie FontAwesome von @expo/vector-icons installieren
import * as Yup from "yup";
import axios from "axios";

import { baseURL } from "../../api/client";
import { Form, FormField, SubmitButton } from "../../components/forms";
import colors from "../../config/colors";
import Screen from "../../components/Screen";
import Text from "../../components/Text";
import useAuth from "../../auth/useAuth";
import ButtonBig from "../../components/ButtonBig";
import DoneScreen from "../../components/DoneScreen";

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required().min(4).label("Aktuelles Passwort"),
  newPassword: Yup.string().required().min(4).label("Neues Passwort"),
  confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwörter müssen übereinstimmen').required().label("Passwort bestätigen"),
});

function EditPasswordScreen({navigation, route}) {
  const { userMail, userProfileName } = route.params;
  const [showPassword, setShowPassword] = useState(false);
  const { user } = useAuth();

  const [updateVisible, setUpdateVisible] = useState(false);

  const handleUpdateComplete = () => {
      setUpdateVisible(false);
  };
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);

  const toggleResetPasswordVisibility = () => {
    setResetPasswordVisible(!resetPasswordVisible);
  }

  const handleResetPassword = async () => {
    
    try {

      const resetPassword = await axios.post(`${baseURL}/users/resetPassword/${user.userMail}`);

      if (resetPassword.status === 200) {
        Alert.alert("E-Mail gesendet", "Bitte überprüfe deinenp E-Mail für das temporäre Passwort.", [
          {
            text: "OK",
            onPress: () => navigation.goBack() // Navigieren Sie zurück zum DataScreen
          }
        ]);
      } else {
        Alert.alert("Fehler", "Das Passwort konnte nicht aktualisiert werden!");
      }
    } catch (error) {
      console.error("Fehler beim Senden der Email:", error);
      Alert.alert("Fehler", "Ein unerwarteter Fehler ist aufgetreten");
    }
  }

  const handleSubmit = async (values, { resetForm }) => {
    
    try {
      // Überprüfung des aktuellen Passworts
      const passwordCheckResult = await axios.post(`${baseURL}/users/getUserPassword/${user.userID}`, {
        currentPassword: values.currentPassword
      });

      if (passwordCheckResult.status === 200) {
        // Aktualisieren des Passworts
        const updateResult = await axios.patch(`${baseURL}/users/patchUserPassword/${user.userID}`, {
          password: values.newPassword
        });

        if (updateResult.status === 200) {
          setUpdateVisible(true);
          resetForm();
        } else {
          Alert.alert("Fehler", "Passwort konnte nicht aktualisiert werden");
        }
      } else {
        Alert.alert("Fehler", "Aktuelles Passwort ist falsch");
      }
    }catch (error) {
      if (axios.isAxiosError(error)) {
        // Hier können Sie auf den HTTP-Statuscode des Fehlers zugreifen
        if (error.response && error.response.status === 401) {
          Alert.alert("Fehler", "Aktuelles Passwort ist falsch");
        } else {
          Alert.alert("Fehler", "Ein unerwarteter Fehler ist aufgetreten");
        }
      } else {
        console.error("Fehler beim Aktualisieren des Passworts:", error);
        Alert.alert("Fehler", "Ein unerwarteter Fehler ist aufgetreten");
      }
    }
  };

  return (
    <Screen style={styles.container}>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 0}
      >
        <ScrollView>
          <Form
            initialValues={{
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <FormField
              name="currentPassword"
              placeholder="Altes Passwort"
              secureTextEntry={!showPassword}
            />
            <FormField
              name="newPassword"
              placeholder="Neues Passwort"
              secureTextEntry={!showPassword}
            />
            <FormField
              name="confirmPassword"
              placeholder="Passwort bestätigen"
              secureTextEntry={!showPassword}
            />
            <TouchableOpacity
              style={styles.eyeIcon}
              onPress={() => setShowPassword(!showPassword)}
            >
              <Text><FontAwesome name={showPassword ? 'eye' : 'eye-slash'} size={24} color="black" />{showPassword?"  Passwörter verbergen" : "  Passwörter zeigen"}</Text>
            </TouchableOpacity>
            
            <SubmitButton title="Passwort speichern" color={colors.success} />
          </Form>
          <TouchableOpacity
            style={styles.forgotPassword}
            onPress={toggleResetPasswordVisibility}
          >
            <Text>
              <MaterialCommunityIcons name="head-question" size={24} color={colors.warning} />
              Passwort vergessen?
            </Text>
          </TouchableOpacity>

          {resetPasswordVisible && (
            <>
              <Text style={styles.resetPasswordText}>
                Wenn du dein Passwort vergessen hast, kannst du dir ein temporäres Passwort per E-Mail anfordern.
              </Text>
              <ButtonBig
                style={styles.resetPasswordButton}
                onPress={handleResetPassword}
              >
                <MaterialCommunityIcons name="send" size={24} color={colors.white} />
                <Text style={styles.resetPasswordTextButton}>Passwort zurücksetzen</Text>
              </ButtonBig>
            </>
          )}
        </ScrollView>
      </KeyboardAvoidingView>
      <DoneScreen
        style={styles.update}
        onDone={handleUpdateComplete}
        visible={updateVisible}
      />
    </Screen>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  eyeIcon: {
    marginTop: 10,
    marginBottom: 10,
  },
  update: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
    backgroundColor: 'transparent',  // Hinzugefügt, um einen transparenten Hintergrund zu gewährleisten
  },
  forgotPassword: {
    marginTop: 10,
    marginBottom: 10,
    alignItems: 'center',
  },
  resetPasswordText: {
    marginTop: 10,
    textAlign: 'center',
  },
  resetPasswordButton: {
    marginTop: 10,
    backgroundColor: colors.primary,
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
  },
  resetPasswordTextButton: {
    color: colors.white,
    paddingLeft: 5
  }
});

export default EditPasswordScreen;
