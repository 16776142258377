import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import ModalNavigator from "./ModalNavigator";  // Achten Sie darauf, dass der Pfad korrekt ist


import HomeScreen from "../screens/HomeScreen";
import ForumScreen from "../screens/forumscreens/ForumScreen";
import DiseasesScreen from "../screens/forumscreens/DiseasesScreen";
import ThreadsScreen from "../screens/forumscreens/ThreadsScreen";
import ThreadScreen from "../screens/forumscreens/ThreadScreen";
import KnowledgeScreen from "../screens/knowledgescreens/KnowledgeScreen";
import AboutSpecialsitterScreen from "../screens/knowledgescreens/AboutSpecialsitterScreen";
import AcademyScreen from "../screens/knowledgescreens/AcademyScreen";
import TipsScreen from "../screens/knowledgescreens/TipsScreen";
import TypesofBenefitsScreen from "../screens/knowledgescreens/TypesOfBenefitsScreen";
import NetworkingScreen from "../screens/networkingscreens/NetworkingScreen";
import NetworkingDetailScreen from "../screens/networkingscreens/NetworkingDetailScreen";
import HolidayInfoScreen from "../screens/holidayscreens/HolidayInfoScreen";
import HolidayGermanyScreen from "../screens/holidayscreens/HolidayGermanyScreen";
import HolidayWorldScreen from "../screens/holidayscreens/HolidayWorldScreen";
import HolidayContentScreen from "../screens/holidayscreens/HolidayContentScreen";
import HolidayDetailScreen from "../screens/holidayscreens/HolidayDetailScreen";

const Stack = createStackNavigator();

const ForumNavigator = () => (
  <Stack.Navigator>
    <Stack.Screen 
      name="Startseite" 
      component={HomeScreen} 
      options={{ headerShown: false }} // Versteckt den Header für HomeScreen
    />
    <Stack.Screen name="Forum" component={ForumScreen} />
    <Stack.Screen name="Krankheiten" component={DiseasesScreen} />
    <Stack.Screen name="Themen" component={ThreadsScreen} />
    <Stack.Screen name="Thema" component={ThreadScreen} />

    <Stack.Screen name="Wissen to Go" component={KnowledgeScreen} />
    <Stack.Screen name="Über Specialsitter" component={AboutSpecialsitterScreen} />
    <Stack.Screen name="Academy" component={AcademyScreen} />
    <Stack.Screen name="Tips" component={TipsScreen} />
    <Stack.Screen name="Die Leistungsarten" component={TypesofBenefitsScreen} />

    <Stack.Screen name="Vernetzung mit anderen Familien" component={NetworkingScreen} />
    <Stack.Screen name="Vernetzung in ???" component={NetworkingDetailScreen} />

    <Stack.Screen name="Ausflug- und Urlaubstips" component={HolidayInfoScreen} />
    <Stack.Screen name="Deutschland" component={HolidayGermanyScreen} />
    <Stack.Screen name="Europa und Weltweit" component={HolidayWorldScreen} />
    <Stack.Screen name="Allgemeine Infos / Erfahrungsberichte" component={HolidayContentScreen} />
    <Stack.Screen name="Details" component={HolidayDetailScreen} />

    <Stack.Screen 
      name="Modal" 
      component={ModalNavigator} 
      options={{ headerShown: false }} 
    />
  </Stack.Navigator>
);

export default ForumNavigator;
