import React, { useState, useEffect } from "react";
import { Platform } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useIsFocused } from '@react-navigation/native';
import axios from "axios";

import { baseURL } from "../api/client";
import AccountNavigator from "./AccountNavigator";
import ForumNavigator from "./ForumNavigator";
import MessageNavigator from "./MessageNavigator";
import useAuth from "../auth/useAuth";
const Tab = createBottomTabNavigator();

const AppNavigator = () => {
  const { user } = useAuth();
  const isFocused = useIsFocused();
  const [unreadNotifications, setUnreadNotifications] = useState();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${baseURL}/notifications/unreadCount/${user.userID}`);
        setUnreadNotifications(response.data.unreadCount);
        } catch (error) {
          console.error("Fehler beim Abrufen der Notifications:", error);
        }
    };
    if(isFocused){
      fetchNotifications();
    }
  }, [isFocused]);


  let tabBarStyle;

  if (Platform.OS === 'android'){
    tabBarStyle = [
      {
        display: 'flex',
        paddingBottom: 10,
        paddingTop: 5,
      },
      null,
    ];
  }else{
    tabBarStyle = [
      {
        display: 'flex',
      },
      null,
    ];
  }

  return (
    <Tab.Navigator 
      screenOptions={{
        tabBarStyle: tabBarStyle
      }}
    >
      <Tab.Screen
        name="Familiennetzwerk"
        component={ForumNavigator}
        options={{
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons name="human-male-female-child" color={color} size={size} />
          ),
        }}
      />
       <Tab.Screen
        name="Nachrichten"
        component={MessageNavigator}
        options={{
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons name="message" color={color} size={size} />
          ),
          tabBarBadge: unreadNotifications > 0 ? unreadNotifications : null,
        }}
      />
      <Tab.Screen
        name="Account"  // Ändern Sie den Namen hier
        component={AccountNavigator}
        options={{
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons name="account" color={color} size={size} />
          ),
          tabBarLabel: 'Account' // Fügen Sie einen Tab-Titel hinzu, um "Account" anzuzeigen
        }}
      />
    </Tab.Navigator>
  );
};

export default AppNavigator;
