import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, Switch, Alert, Linking, AppState } from "react-native";
import * as Notifications from 'expo-notifications';

import Screen from "../../components/Screen";
import colors from "../../config/colors";

function AuthorizationsScreen(props) {
  const [isNotificationsEnabled, setNotificationsEnabled] = useState(false);
  const [appState, setAppState] = useState(AppState.currentState);

  const fetchPermissions = async () => {
    try {
      const notificationStatus = await Notifications.getPermissionsAsync();
      setNotificationsEnabled(notificationStatus.status === 'granted');

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPermissions();
  
    const appStateListener = AppState.addEventListener("change", handleAppStateChange);
  
    return () => {
      appStateListener.remove();
    };
  }, []);

  const handleAppStateChange = (nextAppState) => {
    if (appState.match(/inactive|background/) && nextAppState === "active") {
      fetchPermissions();
    }
    setAppState(nextAppState);
  };

  const handleNotificationToggle = async () => {
    try {
      if (isNotificationsEnabled === true) {
        Alert.alert(
          "Hinweis",
          "Bitte ändern Sie die Einstellungen manuell in den Systemeinstellungen.",
          [
            {
              text: "Einstellungen öffnen",
              onPress: () => Linking.openSettings(),
            },
            {
              text: "Abbrechen",
              onPress: () => {},
              style: "cancel",
            },
          ]
        );
      }else if(isNotificationsEnabled === false){
        const { status } = await Notifications.requestPermissionsAsync();
        if(Platform.OS==="ios"){
          Alert.alert(
            "Hinweis",
            "Bitte ändern Sie die Einstellungen manuell in den Systemeinstellungen.",
            [
              {
                text: "Einstellungen öffnen",
                onPress: () => Linking.openSettings(),
              },
              {
                text: "Abbrechen",
                onPress: () => {},
                style: "cancel",
              },
            ]
          );
        }
        setNotificationsEnabled(status === "granted")
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Screen style={styles.container}>
      <View style={styles.optionContainer}>
        <Text style={styles.optionLabel}>Benachrichtigungen erhalten</Text>
        <Switch
          trackColor={{ false: colors.dark, true: colors.medium }}
          thumbColor={isNotificationsEnabled ? colors.success : colors.light}
          ios_backgroundColor="#3e3e3e"
          onValueChange={handleNotificationToggle}
          value={isNotificationsEnabled}
        />
      </View>

    </Screen>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  optionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  optionLabel: {
    fontSize: 18,
  },
});

export default AuthorizationsScreen;
