// in Ihrer navigations-Datei oder wo auch immer Sie Ihre Navigatoren definieren
import { createStackNavigator } from '@react-navigation/stack';

import AddThreadScreen from "../screens/forumscreens/AddThreadScreen";
import PatchThreadScreen from "../screens/forumscreens/PatchThreadScreen";
import AddForumSuggestionScreen from "../screens/forumscreens/AddForumSuggestionScreen"

const ModalStack = createStackNavigator();

function ModalNavigator() {
  return (
    <ModalStack.Navigator presentation="modal" screenOptions={{ headerShown: false }}>
      <ModalStack.Screen name="AddThread" component={AddThreadScreen} />
      <ModalStack.Screen name="PatchThread" component={PatchThreadScreen} />
      <ModalStack.Screen name="AddSupport" component={AddForumSuggestionScreen} />
    </ModalStack.Navigator>
  );
}

export default ModalNavigator;
