import React from "react";
import { StyleSheet, ScrollView, View } from "react-native";

import Card from "../../components/Card";
import colors from "../../config/colors";
import routes from "../../navigation/routes";
import Screen from "../../components/Screen";

function KnowledgeScreen({ navigation }) {
  
  return (
    <Screen style={styles.screen}>
      <ScrollView>
        <View style={styles.view}>
          <Card
            title="Die Leistungsarten"
            subTitle="Die wichtigsten Leistungsarten erklärt."
            imageUrl={require('../../assets/illustrations/types-of-careship.webp')}
            onPress={() => navigation.navigate(routes.TYPESOFBENEFITSSCREEN)}
          />
          <Card
            title="Tipps im Alltag"
            subTitle="Tips im Alltag, uvm."
            imageUrl={require('../../assets/illustrations/egh-freizeit.webp')}
            onPress={() => navigation.navigate(routes.TIPSSCREEN)}
          />
        </View>
        <View style={styles.view}>
          <Card
            title="Über Specialsitter"
            subTitle="Wer wir sind und welche Mission wir verfolgen."
            imageUrl={require('../../assets/illustrations/map.webp')}
            onPress={() => navigation.navigate(routes.ABOUTSPECIALSITTERSCREEN)}
          />
          <Card
            title="Academy - Podcasts & Artikel mit Wissen to go"
            subTitle="Höre oder lies interessante Inhalte."
            imageUrl={require('../../assets/illustrations/how-to-wiki.webp')}
            onPress={() => navigation.navigate(routes.ACADEMYSCREEN)}
          />
        </View>
      </ScrollView>
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    padding: 10,
    backgroundColor: colors.light,
  },
  view: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  }
});

export default KnowledgeScreen;
