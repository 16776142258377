import React from "react";
import Constants from "expo-constants";
import { StyleSheet, SafeAreaView, View, Platform } from "react-native";

function Screen({ children, style }) {
  return (
    <SafeAreaView style={[styles.screen, style]}>
      <View style={[styles.view, style, Platform.OS === 'web' ? styles.web : null]}>{children}</View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  screen: {
    paddingTop: Constants.statusBarHeight,
    flex: 1,
  },
  view: {
    flex: 1,
  },
  web: {
    maxWidth: 800, // Oder eine andere maximale Breite, die Sie bevorzugen
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 20,
  },
});

export default Screen;
