import React, { useState, useEffect } from "react";
import { StyleSheet, ScrollView, View } from "react-native";
import axios from 'axios';

import Card from "../components/Card";
import colors from "../config/colors";
import routes from "../navigation/routes";
import Screen from "../components/Screen";
import WelcomeModal from "./WelcomeModal";
import useAuth from "../auth/useAuth";
import { baseURL } from "../api/client";

function HomeScreen({ navigation }) {
  const { user } = useAuth();
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  useEffect(() => {
    const getWelcomeModal = async () => {
      try {
        const response = await axios.get(`${baseURL}/users/getSeeWelcome/${user.userID}`);
        if (response.data.seeWelcome==0) setShowWelcomeModal(true);
        } catch (error) {
          console.error("Fehler beim Öffnen der WelcomeModal:", error);
        }
    };
    getWelcomeModal();
  },[]);

  return (
      <Screen style={styles.screen}>
        <WelcomeModal visible={showWelcomeModal} onClose={() => setShowWelcomeModal(false)} />
        <ScrollView>
          <View style={styles.view}>
            <Card
              title="Das Forum"
              subTitle="Teile deine Erfahrungen im Familiennetzwerk"
              imageUrl={require('../assets/illustrations/content-zen.webp')}
              onPress={() => navigation.navigate(routes.FORUM)}
            />
            <Card
              title="Wissen to Go"
              subTitle="Wissen zu Leistungsarten, Tips im Alltag, uvm."
              imageUrl={require('../assets/illustrations/types-of-careship.webp')}
              onPress={() => navigation.navigate(routes.KNOWLEDGESCREEN)}
            />
          </View>
          <View style={styles.view}>
            <Card
              title="Vernetzung mit anderen Familien"
              subTitle="Vernetze dich in deiner Region mit anderen Familien"
              imageUrl={require('../assets/illustrations/familiesbanner.webp')}
              onPress={() => navigation.navigate(routes.NETWORKINGSCREEN)}
            />
            <Card
              title="Ausflug- & Urlaubstips"
              subTitle="Entdecke inspirierende Ideen für Ausflüge und Urlaube"
              imageUrl={require('../assets/illustrations/egh-freizeit.webp')}
              onPress={() => navigation.navigate(routes.HOLIDAYINFOSCREEN)}
            />
          </View>
        </ScrollView>
      </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    padding: 10,
    backgroundColor: colors.light,
  },
  view: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  }
});

export default HomeScreen;
