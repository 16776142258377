import { useContext } from "react";
import jwtDecode from "jwt-decode";

import AuthContext from "./context";
import authStorage from "./storage";

const useAuth = () => {
  const { user, setUser } = useContext(AuthContext);

  const logIn = (authToken) => {
    const user = jwtDecode(authToken);
    setUser(user);
    authStorage.storeToken(authToken);
  };

  const logOut = async () => {
    try {
      // Versuchen Sie, den Logout-Prozess durchzuführen (z.B. Serverbenachrichtigung)
      // ...
    } catch (error) {
      // Fehlerbehandlung, falls erforderlich
      console.error("Fehler beim Logout: ", error);
    } finally {
      // Löschen des Tokens und Aktualisierung des App State
      setUser(null);
      await authStorage.removeToken();
    }
  };

  const getToken = () => authStorage.getToken(); 

  return { user, logIn, logOut, getToken };
};
export default useAuth;
