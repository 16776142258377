import React, {useState} from "react";
import { StyleSheet, View, FlatList, Image, Platform } from "react-native";

import { ListItem, ListItemSeparator } from "../../components/lists";
import ActivityIndicator from "../../components/ActivityIndicator";
import colors from "../../config/colors";
import Icon from "../../components/Icon";
import routes from "../../navigation/routes";
import Screen from "../../components/Screen";
import useAuth from "../../auth/useAuth";

const menuItems = [
  {
    title: "Daten",
    icon: {
      name: "card-account-details",
      backgroundColor: colors.info,
    },
    targetScreen: routes.DATEN,
  },
  {
    title: "Benachrichtigungen",
    icon: {
      name: "message-badge",
      backgroundColor: colors.info,
    },
    targetScreen: routes.AUTHORIZATIONS,
  },
  {
    title: "Konto",
    icon: {
      name: "information-variant",
      backgroundColor: colors.info,
    },
    targetScreen: routes.ACCOUNTDETAIL,
  },
  {
    title: "Rechtliches",
    icon: {
      name: "note-text",
      backgroundColor: colors.info,
    },
    targetScreen: routes.LEGALS,
  },
  // {
  //   title: "Support",
  //   icon: {
  //     name: "lifebuoy",
  //     backgroundColor: colors.info,
  //   },
  //   targetScreen: routes.SUPPORT,
  // },
];

function AccountScreen({ navigation }) {
  const { logOut } = useAuth();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const handleLogOut = async () => {
    console.log('### logaut start');
    setIsLoggingOut(true);
    setTimeout(() => {
      logOut();
      setIsLoggingOut(false);
    }, 3000);
  };
  return ( 
    <Screen style={styles.screen}>
      <ActivityIndicator visible={isLoggingOut} />
      <View style={styles.container}>
        <FlatList
          data={menuItems}
          keyExtractor={(menuItem) => menuItem.title}
          ItemSeparatorComponent={ListItemSeparator}
          renderItem={({ item }) => (
            <ListItem
              title={item.title}
              IconComponent={
                <Icon
                  name={item.icon.name}
                  backgroundColor={item.icon.backgroundColor}
                />
              }
              onPress={() => navigation.navigate(item.targetScreen)}
            />
          )}
        />
      </View>
      <ListItem
        title="Ausloggen"
        IconComponent={<Icon name="logout" backgroundColor={colors.warning} />}
        onPress={handleLogOut}
      />
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    backgroundColor: colors.light,
    ...Platform.select({
      web: {
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: 20,
      },
    }),
  },
  container: {
    marginVertical: 20,
  },
  containerItem: {
    alignItems: "center",
    flexDirection: "row",
    padding: 15,
    backgroundColor: colors.white,
  },
  detailsContainer: {
    flex: 1,
    marginLeft: 10,
    justifyContent: "center",
  },
  image: {
    width: 70,
    height: 70,
    borderRadius: 35,
  },
  subTitle: {
    color: colors.medium,
  },
  title: {
    fontWeight: "500",
  },
});

export default AccountScreen;
