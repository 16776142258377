import React from "react";
import { StyleSheet, View, TouchableOpacity, Image, Linking } from "react-native";
import { Rating, AirbnbRating } from 'react-native-elements';

import Text from "./Text";
import colors from "../config/colors";

function HolidayCard({ link, title, description, comments, rating, onPress, image }) {
  return (
    <View style={styles.card}>
        <Image source={image} style={styles.image} />
        <View style={styles.textContainer}>
            <Text style={styles.title}>{title}</Text>
            <Rating
                imageSize={20}
                readonly
                startingValue={rating}
                style={ styles.rating }
            />
            <Text numberOfLines={4} style={styles.description}>{description}</Text>
            <Text style={styles.comments}>{comments} Kommentare</Text>
            <View style={styles.buttonContainer}>
                <TouchableOpacity onPress={() => Linking.openURL(link)} style={styles.linkButton}>
                    <Text style={styles.linkText}>Zum Anbieter</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={onPress} style={styles.button}>
                    <Text style={styles.buttonText}>Mehr erfahren</Text>
                </TouchableOpacity>
            </View>
        </View>
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    flexDirection: "row",
    backgroundColor: "#fff",
    marginVertical: 10,
    borderRadius: 10,
    overflow: "hidden",
  },
  image: {
    width: "25%",
    height: 175,
  },
  textContainer: {
    padding: 10,
    width: "75%",
  },
  title: {
    fontWeight: "bold",
    marginBottom: 5,
  },
  linkText: {
    color: colors.primary,
    fontSize: 14,
  },
  comments: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  button: {
    borderWidth: 1,
    borderColor: colors.medium,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    paddingHorizontal: 10,
  },
  buttonText: {
    color: colors.dark,
    fontSize: 14,
  },
  linkButton: {
    borderWidth: 1,
    borderColor: colors.primary,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    paddingHorizontal: 10,
  },
  rating: {
    marginBottom: 5,
    marginTop: 5,
    alignSelf: 'flex-start',
  },
  description: {
    fontSize: 12,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    },
});

export default HolidayCard;
