import React from "react";
import { StyleSheet, View, ScrollView, Image, Linking } from "react-native";
import { MaterialCommunityIcons } from '@expo/vector-icons';

import colors from "../../config/colors";
import Screen from "../../components/Screen";
import Text from "../../components/Text";
import ButtonBig from "../../components/ButtonBig";

import image0 from "../../assets/illustrations/social-insurance.webp";
import image1 from "../../assets/illustrations/verhinderungspflege.webp";
import image2 from "../../assets/illustrations/egh-schule.webp";
import image3 from "../../assets/illustrations/egh-kita.webp";
import image4 from "../../assets/illustrations/persoenliches-budget.webp";
import image5 from "../../assets/illustrations/egh-privat.webp";
import image6 from "../../assets/illustrations/work-at-night-and-on-feasts.webp";
import image7 from "../../assets/illustrations/tasklist-profile.webp";
import image8 from "../../assets/illustrations/personal-data-changing-history.webp";


function TypesOfBenefitsScreen({navigation}) {
  const link = 'http://specialsitter.de/beratungstool';
  const goTo = () => {
    Linking.openURL(link);
  };

  return (
    <Screen style={styles.screen}>
      <ScrollView>
        <Image source={image0} style={styles.image} />
        <View style={styles.headerContainer}>
          <Text style={styles.headerSubtitle}>Wir bieten vielfältige und individuelle Lösungen. Nicht fündig geworden? Kontaktiert uns für eine Beratung.</Text>
        </View>

        <Image source={image1} style={styles.image} />
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Verhinderungspflege - §39 SGB XI</Text>
          <Text style={styles.sectionSubTitle}>Betreuung - Babysitting - Wöchentliche Unterstützung</Text>
          <Text style={styles.sectionContent}>
          Verhinderungspflege kommt dann zum Einsatz, wenn die Pflegeperson einer zu pflegenden Person (ab Pflegegrad 2) verhindert ist. Diese Hilfe kann auch stundenweise genutzt werden und bedeutet praktisch oft, dass bspw. einmal wöchentlich ein/e Mitarbeiter/in entweder zu euch nach Hause kommt, oder Wege/Therapien begleitet.
          </Text>
          <Text style={styles.sectionContent}>
          Außerdem kann hier die Hälfte des Kurzzeitpflegebudgets genutzt werden, wodurch sich das Budget auf 2418 € erhöht, was je nach Stundensatz bei uns ca. 63-75 Stunden im Jahr bedeutet. Wie die Stundensätze entstehen und sich zusammensetzen, kannst du *hier* einsehen.
          </Text>
          <Text style={styles.sectionContent}>
          Du musst dich um nichts kümmern. Wenn du willst, erstellen wir den Antrag fertig ausgefüllt für dich zur Unterschrift. Auch die Abrechnung funktioniert vollautomatisch. Gemeinsam halten wir dann Übersicht, über eure jährlichen Stunden.
          </Text>
        </View>

        <Image source={image2} style={styles.image} />
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Pädagogische Schulbegleitung - §35a SGB VIII</Text>
          <Text style={styles.sectionSubTitle}>Eingliederungshilfe für seelisch behinderte Kinder und Jugendliche</Text>
          <Text style={styles.sectionContent}>
          Schulintegrationshilfen werden über die Jugendämter finanziert, wenn Kinder und Jugendliche eine sogenannte „seelische Behinderung“ haben (oft zählen dazu Autismus-Spektrum-Störungen, AD(H)S, Bindungs-Störungen, …).
          </Text>
          <Text style={styles.sectionContent}>
          Die Kinder besuchen meist Regelschulen und haben durch das Jugendamt finanzierten Anspruch auf diese Unterstützung. Hierbei handelt es sich immer um ausgebildete Fachkräfte, die euer Kind begleiten und mit dem Helfernetz gemeinsam gezielt pädagogisch arbeiten.
          </Text>
          <Text style={styles.sectionContent}>
          Wer bei uns landet, hat meist schon eine Bewilligung für diese Hilfeart erhalten und sucht nun nach einem Träger. Wir würden uns aber auch gemeinsam mit euch um die Antragstellung und die Bewilligung kümmern.
          </Text>
        </View>

        <Image source={image3} style={styles.image} />
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Eingliederungshilfe - SGB IX</Text>
          <Text style={styles.sectionSubTitle}>Schulassistenz - Kitassistenz - Elternassistenz - Jede Form von Gleichstellung</Text>
          <Text style={styles.sectionContent}>
          Diese Leistung beinhaltet soziale Teilhabe und Teilhabe an Bildung, also: Schulassistenz / Kita-Assistenz, Elternassistenz, soziale Assistenz und wird in den meisten Bundesländern über Antragsstellung bei den Sozialämtern gewährt.
          </Text>
          <Text style={styles.sectionContent}>
          Schulassistenz kann hier sowohl eine reine Ausgleichsleistung sein, wenn ein Kind bspw. Diabetes hat oder auf einen Rollstuhl angewiesen ist und hierbei Unterstützung benötigt. Es kann aber ebenso eine zielgerichtete pädagogische Leistung sein, bei der Fachkräfte zum Einsatz kommen. Gleiches gilt bei der Kita-Assistenz.
          </Text>
          <Text style={styles.sectionContent}>
          Elternassistenz kann ebenfalls als reine Nachteils-Ausgleichsleistung verstanden werden, wenn körperliche Beeinträchtigungen bei einem Elternteil vorliegen und bei der Elternschaft demnach ganz praktisch assistiert wird. Auch hier kann eine Hilfe pädagogisch zielgerichtet sein, wenn zum Beispiel eine psychische Erkrankung / seelische Behinderung vorliegt, durch die Unterstützung bei der Elternschaft gewünscht wird. Es sind keine sogenannten „Zwangskontexte“. Eltern können diesen Antrag selbst stellen.
          </Text>
          <Text style={styles.sectionContent}>
          Die Hilfe zur Teilhabe am Leben in der Gemeinschaft kann aus vielfältigen Gründen gewährt werden. Häufig handelt es sich um konkrete Unterstützung bei der Verselbständigung von Jugendlichen mit Lernschwierigkeiten, aber es kann ebenso ein Mensch mit körperlicher Einschränkung diesen Anspruch geltend machen, wenn er bspw. Begleitung bei seiner Freizeitgestaltung wünscht/braucht.
          </Text>
        </View>

        <Image source={image4} style={styles.image} />
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Persönliches Budget</Text>
          <Text style={styles.sectionSubTitle}>Geld erhalten, selbst verwalten und Personal damit einkaufen</Text>
          <Text style={styles.sectionContent}>
          Das persönliche Budget soll als Geldleistung Menschen mit Behinderung für mehr Selbstbestimmung in den Bereichen „Leistung zur medizinischen Rehabilitation, Leistungen zur Teilhabe im Arbeitsleben und Leistungen zur Sozialen Teilhabe“ dienen.
          </Text>
          <Text style={styles.sectionContent}>
          Der Umfang der Leistung wird bedarfsorientiert ermittelt und variiert daher enorm. Die Kostenträger des persönlichen Budgets sind je nach Thematik verschieden, zur Antragsunterstützung gibt es deutschlandweit kostenfreie Beratungsstellen. Hilfen, die über das persönliche Budget laufen, sind durch eine Zielvereinbarung konkretisiert und somit gemeinsam inhaltlich abgesteckt.
          </Text>
          <Text style={styles.sectionContent}>
          Besonders das Thema "Trägerübergreifendes Persönliches Budget" kann für Familien interessant sein, die an mehreren Stellen Unterstützungsbedarf haben. Wir helfen gern bei Beratung, Beantragung und telefonieren uns gemeinsam mit euch durch.
          </Text>
        </View>

        <Image source={image5} style={styles.image} />
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Hilfe in Notsituationen - §20 SGB VIII</Text>
          <Text style={styles.sectionSubTitle}>Familienpflege - Eltern krank, auf Reha, verletzt, Operation</Text>
          <Text style={styles.sectionContent}>
          Immer wenn Eltern oder Erziehungsberechtigte für einen bestimmten Zeitraum aus gesundheitlichen Gründen nicht in der Lage sind ihre Kinder zu betreuen, greift die Hilfe in Notsituationen.
          </Text>
          <Text style={styles.sectionContent}>
          Wir können bei euch zu Hause betreuen, in eine Wohngruppe ziehen oder mit ins Krankenhaus kommen, egal was nötig ist, um euch zu helfen.
          </Text>
          <Text style={styles.sectionContent}>
          Diese Hilfe wird in den meisten Bundesländern vom Sozialamt bezahlt und der Antrag muss unbedingt vor Beginn des Bedarfs gestellt seind. Wir helfen gern bei der Antragstellung und nehmen umgehend Kontakt zu Ämtern auf.
          </Text>
        </View>

        <Image source={image6} style={styles.image} />
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Häusliche Krankenpflege - §37 SGB V</Text>
          <Text style={styles.sectionSubTitle}>Verordnung vom Arzt - Intensive Pflege oder Beobachtung</Text>
          <Text style={styles.sectionContent}>
          Häusliche Krankenpflege wird vom Arzt verordnet und bezieht sich auf eine sehr regelmäßige physische Versorgung, die bei chronischen Erkrankungen nötig ist.
          </Text>
          <Text style={styles.sectionContent}>
          In bestimmten Fällen ( Wo kein Pflegedienst gefunden wurde ), können wir solche Einsätze übernehmen. In diesen Fällen schulen wir unser Personal für genau diesen Einsatz und versuchen dann langfristig eine Lücke zu schließen, bis ein Pflegedienst gefunden wird.
          </Text>
          <Text style={styles.sectionContent}>
          Die Antragstellung benötigt eine Verordnung und eine Liste mit Absagen von Pflegediensten. Wir kümmern uns gern um die Gespräche und die Verhandlung mit der Krankenkasse.
          </Text>
        </View>

        <Image source={image7} style={styles.image} />
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Privat zahlen</Text>
          <Text style={styles.sectionSubTitle}>Firma zahlt - Direktvertrag</Text>
          <Text style={styles.sectionContent}>
          Ihr wendet euch mit eurem Anliegen an uns und wir prüfen, ob es durch gängige Hilfeformen bzw. Sonder-/Einzelvereinbarungen von dem jeweiligen Kostenträger getragen werden kann.
          </Text>
          <Text style={styles.sectionContent}>
          Sollte dies nicht möglich sein, ist eine private Finanzierung oder ein Firmenauftrag kein Problem. Unser typischer Stundensatz liegt bei 38€/h, kann aber in Notfällen variieren.
          </Text>
        </View>

        <Image source={image8} style={styles.image} />
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Hilfe zur Pflege</Text>
          <Text style={styles.sectionSubTitle}>Grundpflege nötig, die nicht allein gestemmt werden kann</Text>
          <Text style={styles.sectionContent}>
          Hilfe zur Pflege ist eine langfristige Unterstützung des Sozialamtes für die Grundpflege. Dabei bezieht diese sich auf die rein körperlichen Pflegetätigkeiten, die im Alltag nötig sind.
          </Text>
          <Text style={styles.sectionContent}>
          Wir sprechen gern gemeinsam über Möglichkeiten und treffen uns gemeinsam mit dem Sozialamt, um eine mögliche Unterstützung auszuloten.
          </Text>
        </View>
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Hast du noch Fragen? Dann nutze unser Beratungstool auf specialsitter.de:</Text>
          <ButtonBig color="info" onPress={goTo}>
            <MaterialCommunityIcons name="page-next-outline" size={24} color={colors.white} style={styles.icon} />
            <Text style={styles.text}>zum Beratungstool</Text>
          </ButtonBig>
        </View>
      </ScrollView>
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    padding: 10,
  },
  headerContainer: {
    // Ihr Styling
  },
  text: {
    color: colors.white,
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  icon: {
    marginHorizontal: 10,
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  headerSubtitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  sectionContainer: {
    paddingVertical: 20,
  },
  sectionTitle: {
    fontSize: 26,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  sectionContent: {
    marginVertical: 20,
    fontSize: 16,
  },
  image: {
    width: '100%',
    height: 300,
    resizeMode: 'cover',
  },
  downloadButton: {
    // Ihr Styling
  },
  downloadButtonText: {
    fontSize: 16,
    color: colors.primary,
  },
});


export default TypesOfBenefitsScreen;

