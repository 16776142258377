import React from "react";
import { StyleSheet, ScrollView, View } from "react-native";

import Card from "../../components/Card";
import colors from "../../config/colors";
import routes from "../../navigation/routes";
import Screen from "../../components/Screen";

function HolidayInfoScreen({ navigation }) {
  
  return (
    <Screen style={styles.screen}>
      <ScrollView>
        <View style={styles.view}>
          <Card
            title="Deutschland"
            subTitle=""
            imageUrl={require('../../assets/illustrations/image.webp')}
            onPress={() => navigation.navigate(routes.HOLIDAYGERMANYSCREEN)}
          />
          <Card
            title="Europa und Weltweit"
            subTitle=""
            imageUrl={require('../../assets/illustrations/egh-freizeit.webp')}
            onPress={() => navigation.navigate(routes.HOLIDAYWORLDSCREEN)}
          />
        </View>
        <View style={styles.view}>
          <Card
            title="Allgemeine Infos / Erfahrungsberichte"
            subTitle=""
            imageUrl={require('../../assets/illustrations/hw-large.webp')}
            onPress={() => navigation.navigate(routes.HOLIDAYCONTENTSCREEN)}
          />
        </View>
      </ScrollView>
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    padding: 10,
    backgroundColor: colors.light,
  },
  view: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  }
});

export default HolidayInfoScreen;
