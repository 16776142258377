import React, { useRef, useEffect, useState } from "react";
import { StyleSheet, View, Image, TouchableOpacity, FlatList, TextInput, ActivityIndicator } from "react-native";
import { Rating, AirbnbRating } from 'react-native-elements';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import axios from "axios";
import moment from 'moment';
import { Left } from 'react-native-component-separator';

import { baseURL } from "../../api/client";
import useAuth from "../../auth/useAuth";
import colors from "../../config/colors";
import Screen from "../../components/Screen";
import Text from "../../components/Text";


function HolidayDetailScreen({ route }) {
    const { user } = useAuth();
    const [holidayData, setHolidayData] = useState(route.params.holidayData);
    const [isLoading, setIsLoading] = useState(false);
    const [holidayCommentsData, setHolidayCommentsData] = useState([]);
    const [holidayCommentContent, setHolidayCommentContent] = useState("");
    const [holidayUserRating, setHolidayUserRating] = useState(0);
    const baseImageURL = `${baseURL}/images/holiday/`;

    
    useEffect(() => {
        const fetchCommentsData = async () => {
          setIsLoading(true);
          try {
            const response = await axios.get(`${baseURL}/holidayComments/getHolidayComments/${holidayData.holidayID}`);
            setHolidayCommentsData(response.data);
            } catch (error) {
              console.error("Fehler beim Abrufen der WorldHoliday:", error);
            }
            setIsLoading(false);
        };
        const fetchHolidayUserRating = async () => {
            setIsLoading(true);
            try {
              const response = await axios.get(`${baseURL}/holidayRatings/getHolidayRating/${user.userID}/${holidayData.holidayID}`);
              setHolidayUserRating(response.data); 
            } catch (error) {
            console.error("Fehler beim Abrufen der eigenen Holiday Rating:", error);
            }
            setIsLoading(false);
        };
    
        fetchCommentsData();
        fetchHolidayUserRating()
    }, []);

    const fetchCommentsData = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(`${baseURL}/holidayComments/getHolidayComments/${holidayData.holidayID}`);
          setHolidayCommentsData(response.data);
          } catch (error) {
            console.error("Fehler beim Abrufen der WorldHoliday:", error);
          }
          setIsLoading(false);
      };

    const flatListRef = useRef(null);

    useEffect(() => {
        if (flatListRef.current) {
            flatListRef.current.scrollToEnd({ animated: true });
        }
    }, [holidayCommentsData]); 
    
    const handleSendComment = async () => {
        if(!holidayCommentContent==''){
          try {
            let userID=user.userID;
            let holidayID=holidayData.holidayID;
            const response = await axios.post(`${baseURL}/holidayComments/addHolidayComment`, {holidayID, userID, holidayCommentContent});
            fetchCommentsData();
            setHolidayCommentContent("");
          } catch (error) {
            console.error("Fehler beim Senden des Kommentars:", error);
          }
        }
    };

    const handleRatingCompleted = async (rating) => {
        try {
            await axios.post(`${baseURL}/holidayRatings/addHolidayRating/${user.userID}/${holidayData.holidayID}`, { holidayRating: rating });
            fetchHolidayData(); // Funktion, um aktualisierte Urlaubsdaten zu erhalten
        } catch (error) {
            console.error("Fehler beim Senden des Ratings:", error);
        }
    };
    
    const handleDeleteRating = async () => {
        try {
            await axios.post(`${baseURL}/holidayRatings/deleteHolidayRating/${user.userID}/${holidayData.holidayID}`);
            fetchHolidayData(); // Funktion, um aktualisierte Urlaubsdaten zu erhalten
        } catch (error) {
            console.error("Fehler beim Löschen des Ratings:", error);
        }
    };
    const fetchHolidayData = async () => {
        try {
            const response = await axios.get(`${baseURL}/holidays/getHoliday/${holidayData.holidayID}`);
            setHolidayData(response.data);
        } catch (error) {
            console.error("Fehler beim Abrufen des Holidays:", error);
        }
        try {
            const response = await axios.get(`${baseURL}/holidayRatings/getHolidayRating/${user.userID}/${holidayData.holidayID}`);
            setHolidayUserRating(response.data); 
            const functionChanged = async () => {
                // Ihr bestehender Code für den Fetch
                if (typeof changed === 'function') {
                    changed(holidayUserRating.holidayRating);
                }
            };
            functionChanged();
        } catch (error) {
            console.error("Fehler beim Abrufen der eigenen Holiday Rating:", error);
        }
    };

    return (
        <Screen style={styles.screen}>
            <FlatList
                ListHeaderComponent={
                    <>
                        <View style={styles.imageContainer}>
                            <Image
                                source={{ uri: `${baseImageURL}${holidayData.holidayImage}` }}
                                style={styles.image}
                            />
                        </View>
                        <View>
                            <Text style={styles.title}>{holidayData.holidayTitle}</Text>
                            <Rating
                                imageSize={10}
                                readonly
                                startingValue={holidayData.holidayRating}
                                style={ styles.rating }
                            />
                            <Text style={styles.previewText}>{holidayData.holidayDescription}</Text>
                        </View>
                        <TouchableOpacity onPress={() => Linking.openURL(holidayData.holidayUrl)} style={styles.linkButton}>
                            <Text style={styles.linkText}>Zum Anbieter</Text>
                        </TouchableOpacity>
                        <View style={styles.ratingContainer}>
                            <View>
                                {!holidayUserRating ?<Text style={styles.ratingText}>Gib deine Bewertung ab:</Text>:<Text style={styles.ratingUserText}>Deine Bewertung</Text>}
                                {!holidayUserRating ? null : 
                                    <TouchableOpacity onPress={handleDeleteRating}>
                                        <Text style={styles.ratingText}>
                                            <MaterialCommunityIcons name="delete-circle" color={colors.danger} size={16} />
                                            Entfernen?
                                        </Text>
                                    </TouchableOpacity>
                                }
                            </View>
                            <AirbnbRating 
                                onFinishRating={handleRatingCompleted}
                                style={styles.airbnbRating}
                                count={5}
                                reviews={["Schrecklich", "Schlecht", "OK", "Gut", "Großartig"]}
                                defaultRating={!holidayUserRating ? 0 : holidayUserRating.holidayRating}
                                size={25}
                            />
                        </View>
                        <Left borderColor="#e0e0e0" color="#616161" >{holidayCommentsData.length} Kommentare</Left>
                    </>
                }
                ref={flatListRef}
                data={holidayCommentsData}
                keyExtractor={(item) => item.holidayCommentID.toString()}
                renderItem={({ item }) => (
                    <>
                        {isLoading ? (
                            <View style={styles.loaderContainer}>
                                <ActivityIndicator size="large" color={colors.primary} />
                            </View>
                        ) : (
                                    <View style={[styles.messageContainer, item.userID === user.userID ? styles.myMessageContainer : styles.otherMessageContainer]}>
                                        <View style={item.userID === user.userID ? styles.myMessageBubble : styles.otherMessageBubble}>
                                            <View style={styles.commentHeader}>
                                            <Text style={[styles.commentDate, item.userID === user.userID ? styles.myCommentDate : null]}>
                                                {item.user ? item.user.userProfileName : 'Anonym'}
                                            </Text>
                                            <Text style={[styles.commentDate, item.userID === user.userID ? styles.myCommentDate : null]}>
                                                {moment(new Date(item.inserted_at)).format('DD.MM.YYYY HH:mm')}
                                            </Text>
                                            </View>
                                            <Text style={[styles.messageText, item.userID === user.userID ? styles.myMessageText : null]}>
                                            {item.holidayCommentContent}
                                            </Text>
                                        </View>
                                    </View>
                        )}
                    </>
                )}
                ListFooterComponent={   
                    <>
                        <View style={styles.commentInputContainer}>
                        <TextInput
                            style={styles.commentInput}
                            value={holidayCommentContent}
                            onChangeText={setHolidayCommentContent}
                            placeholder="Einen Kommentar hinzufügen..."
                            multiline
                            />
                        <TouchableOpacity onPress={handleSendComment} style={styles.sendButton}>
                            <MaterialCommunityIcons name="send" color={colors.white} size={26} />
                        </TouchableOpacity>
                        </View>
                    </>
                }
            />
        </Screen>
    );
}

const styles = StyleSheet.create({
    screen: {
        padding: 10,
        backgroundColor: colors.light,
    },
    title: {
        fontWeight: "bold",
    },
    previewText: {
        fontSize: 14,
    },
    image: {
        position: "relative",
        resizeMode: "contain",
        width: '100%',
        height: 300,
    },
    imageContainer: {
        height: 300,
        marginBottom: 20,
    },
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
        width: 0,
        height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    textInput: {
        width: '80%',
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        marginBottom: 10,
    },
    messageContainer: {
        marginBottom: 10,
        flexDirection: 'row',
    },
    commentHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 5,
    },
    commentDate: {
      fontSize: 12,
      color: colors.medium,
    },
    myCommentDate: {
      color: colors.white,
    },
    myMessageContainer: {
        justifyContent: 'flex-end',
    },
    otherMessageContainer: {
        justifyContent: 'flex-start',
    },
    myMessageBubble: {
        width: '80%',
        backgroundColor: colors.success,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        padding: 10,
    },
    otherMessageBubble: {
        width: '80%',
        backgroundColor: colors.white,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        padding: 10,
    },
    messageText: {
        fontSize: 16,
        color: colors.black,
    },
    myMessageText: {
        color: colors.white,
    },
    fab: {
        position: 'absolute',
        right: 10,
        bottom: 10,
        backgroundColor: colors.primary,
        borderRadius: 50,
        width: 56,
        height: 56,
        justifyContent: 'center',
        alignItems: 'center',
    },
    threadMessage: {
        paddingBottom: 30,
    },
    myMessageSender: {
        fontSize: 12,
        color: colors.light,
    },
    otherMessageSender: {
        fontSize: 12,
        color: colors.primary,
    },
    rating: {
        marginBottom: 5,
        marginTop: 5,
        alignSelf: 'flex-start',
    },
    airbnbRating: {
        marginBottom: 5,
        marginTop: 5,
        alignSelf: 'flex-start',
    },
    ratingContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: "center",
        paddingTop: 10,
        paddingBottom: 10,
    },
    linkButton: {
        borderWidth: 1,
        borderColor: colors.primary,
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
        paddingHorizontal: 15,
        marginVertical: 10,
    },
    linkText: {
        color: colors.primary,
        fontSize: 14,
    },
    ratingText: {
        color: colors.warning,
        fontSize: 16
    },
    ratingUserText: {
        color: colors.primary,
        fontSize: 16
    },
    commentText: {
        fontSize: 20,
        fontWeight: "bold",
        paddingBottom: 10,
    },
    commentInputContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 10,
    },
    commentInput: {
      flex: 1,
      borderWidth: 1,
      borderColor: colors.medium,
      borderRadius: 25,
      padding: 15,
      marginRight: 10,
      textAlignVertical: 'center'
    },
    placeholderText: {
      color: 'grey',
    },
    sendButton: {
      width: 50,
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.primary,
      borderRadius: 25,
    },
});

export default HolidayDetailScreen;
