import React from "react";
import { StyleSheet, View, ScrollView, Image, Linking } from "react-native";
import { MaterialCommunityIcons } from '@expo/vector-icons';

import colors from "../../config/colors";
import Screen from "../../components/Screen";
import Text from "../../components/Text";
import ButtonBig from "../../components/ButtonBig";

import image1 from "../../assets/illustrations/marketing.webp";
import image2 from "../../assets/illustrations/familiesbanner.webp";
import image3 from "../../assets/illustrations/story.webp";


function AboutSpecialsitterScreen({navigation}) {
  const pdfUrl = 'http://specialsitter.de/newwebsite/assets/files/konzept.pdf';
  const openPDF = () => {
    Linking.openURL(pdfUrl);
  };

  return (
    <Screen style={styles.screen}>
      <ScrollView>
        <Image source={image1} style={styles.image} />
        <View style={styles.headerContainer}>
          <Text style={styles.headerSubtitle}>Wer wir sind und was uns zu dem gemacht hat.</Text>
        </View>
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Wer sind wir überhaupt?</Text>
          <Text style={styles.sectionContent}>
            Wir sind Specialsitter, ein Team bestehend aus über 450 qualifizierten Fachkräften und kompetenten Quereinsteigenden aus dem sozialen Bereich und unsere Geschäftsführer Friedrich und Sebastian.
          </Text>
          <Text style={styles.sectionContent}>
          Wir finden die passende Hilfe für euch. Unsere Leistungen umfassen die maßgeschneiderte Vermittlung passender Unterstützung, unabhängig davon, ob es sich um ein paar Stunden am Abend, die Begleitung zur Schule, die Diabetesversorgung oder sogar eine Betreuung über mehrere Wochen während des Urlaubs handelt. Wir stehen Euch zur Verfügung und bemühen uns, eure  individuellen Anforderungen zu erfüllen und eine zuverlässige Assistenzlösung anzubieten.
          </Text>
        </View>
        <Image source={image2} style={styles.image} />
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Wer macht uns anders?</Text>
          <Text style={styles.sectionContent}>
            Im Mittelpunkt unserer Arbeit steht genau eine Leistung: „Zuhören, was du brauchst und das möglich machen“.
          </Text>
          <Text style={styles.sectionContent}>
          Unser Erfolg basiert darauf, dass wir kontinuierlich unsere Betreuungsstrategien anpassen und optimieren, um den sich wandelnden Anforderungen der begleiteten Personen gerecht zu werden. In der praktischen Umsetzung bedeutet dies, dass wir pflegerische Dienstleistungen mit bedarfsgerechter pädagogischer Förderung kombinieren. Darüber hinaus vermeiden wir lange Wartelisten und bemühen uns, auf Anfragen so schnell wie möglich zu reagieren.
          </Text>
          <Text style={styles.sectionContent}>
          Wo andere Anbieter Betreuung kategorisch ablehnen, sagen wir erst recht und mit Absicht „Ja“.
          </Text>
          <Text style={styles.sectionContent}>
          Wir unterstützen euch auch in finanziellen Angelegenheiten. Tatsächlich sind die Gesetze zugunsten der Familien ausgelegt, und es gibt eine Vielzahl von finanziellen Unterstützungsmöglichkeiten von Ämtern und Krankenkassen. Leider wissen viele Familien nicht, welche Leistungen sie beantragen können. Seit 2015 schließen wir diese Wissenslücke. Wir stehen euch nicht nur bei der Betreuung zur Seite, sondern helfen euch auch dabei, die finanziellen Mittel zu beantragen.
          </Text>
        </View>
        <Image source={image3} style={styles.image} />
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Unsere Vision</Text>
          <Text style={styles.sectionContent}>
          Unsere Reise begann in Berlin, dem Wohnort unserer Geschäftsführer. Durch die positive Resonanz seitens Familien, Ämtern und Fachkräften konnten wir unser Engagement auf neun Standorte im gesamten Bundesgebiet ausdehnen. Doch unser Blick richtet sich weiter in die Zukunft: Unsere Vision besteht darin, Familien in ganz Deutschland flächendeckend zu unterstützen.
          </Text>
          <Text style={styles.sectionContent}>
          Unser Ziel ist es, für alle jungen Menschen und Familien mit Unterstützungsbedarf die passende Versorgung zu ermöglichen und das so unkompliziert wie möglich. Dies erfordert, dass wir die beste Arbeitgeberin im sozialen Bereich sind. Nur auf diese Weise können wir eine langfristige Zusammenarbeit mit unserem Team gewährleisten und die bestehende Versorgungslücke erfolgreich schließen.
          </Text>
          <Text style={styles.sectionContent}>
          Du möchtest noch mehr über uns erfahren? Dann sie dir unser Konzept an:
          </Text>
          <ButtonBig color="success" onPress={openPDF}>
            <MaterialCommunityIcons name="tray-arrow-down" size={24} color={colors.white} style={styles.icon} />
            <Text style={styles.text}>Konzept PDF herunterladen</Text>
          </ButtonBig>
        </View>
      </ScrollView>
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    padding: 10,
  },
  headerContainer: {
    // Ihr Styling
  },
  text: {
    color: colors.white,
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  icon: {
    marginHorizontal: 4,
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  headerSubtitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  sectionContainer: {
    paddingVertical: 20,
  },
  sectionTitle: {
    fontSize: 26,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  sectionContent: {
    marginVertical: 20,
    fontSize: 16,
  },
  image: {
    width: '100%',
    height: 300,
    resizeMode: 'cover',
  },
  downloadButton: {
    // Ihr Styling
  },
  downloadButtonText: {
    fontSize: 16,
    color: colors.primary,
  },
});


export default AboutSpecialsitterScreen;

