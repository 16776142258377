import React, {useState} from "react";
import { Formik } from 'formik';
import Modal from "react-native-modal";
import axios from "axios";

import { StyleSheet, View, Alert, ScrollView, KeyboardAvoidingView, Platform } from "react-native";
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { baseURL } from "../../api/client";
import colors from "../../config/colors";
import Screen from "../../components/Screen";
import Text from "../../components/Text";
import ButtonBig from "../../components/ButtonBig";
import { TextInput } from "../../components/forms";
import useAuth from "../../auth/useAuth";
import DoneScreen from "../../components/DoneScreen";


function AddThreadScreen({ isVisible, onClose, onSubmit, userID, forumID }) {
  const { user } = useAuth();
  const [uploadVisible, setUploadVisible] = useState(false);

  const handleFormSubmit = async (values) => {
    if(values.threadTitle==''){
      Alert.alert(
        "Du hast keinen Titel!",
        "Ohne Titel kannst du leider kein Thema erstellen. Überlege, was am besten als Überschrift zu deinem Text passen würde.",
      );
      return;
    }else if(values.threadDescription==''){
      Alert.alert(
        "Das hast keinen Haupttext!",
        "Da ist dir sicherlich nur ein Fehler unterlaufen. Schreibe ein paar Sätze, um dein Thema zu vervollständigen.",
      );
      return;
    }else {
      try {
        let userID=user.userID;
        let threadTitle=values.threadTitle;
        let threadPreview=values.threadPreview;
        let threadDescription=values.threadDescription;
        const response = await axios.post(`${baseURL}/threads/addThread`, {userID, forumID, threadTitle, threadPreview, threadDescription});
      } catch (error) {
        Alert.alert(
          "Das hat nicht funktioniert!",
          "Leider ist ein Fehler aufgetreten. Versuche es bitte nochmal.",
        );
        console.error("Fehler beim Abrufen des Beitrags:", error);
      }
    }
    setUploadVisible(true);
  }

  const handleUploadComplete = () => {
      setUploadVisible(false);
      onClose();  
  };

  return (
    <Modal isVisible={isVisible} style={{ margin: 0 }}>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 0}
      >
        <Screen style={styles.screen}>
          <ScrollView>
            <Text style={styles.headline}>Erstelle dein neues Thema</Text>
            <View style={styles.view}>
              <Formik
                initialValues={{ 
                  threadTitle: '', 
                  threadPreview: '', 
                  threadDescription: '' 
                }}
                onSubmit={handleFormSubmit}
              >
                {({ handleChange, handleSubmit, values }) => (
                  <>
                    <TextInput 
                      name="threadTitle" 
                      placeholder="Titel des Themas" 
                      onChangeText={handleChange('threadTitle')}
                      value={values.threadTitle}
                    />
                    <TextInput 
                      name="threadPreview" 
                      placeholder="Gib ein kleines Intro..." 
                      multiline={true}
                      numberOfLines={3}
                      onChangeText={handleChange('threadPreview')}
                      value={values.threadPreview}
                    />
                    <TextInput 
                      name="threadDescription" 
                      placeholder="Hier hast du Platz für deinen Inhalt..." 
                      multiline={true}
                      numberOfLines={10}
                      onChangeText={handleChange('threadDescription')}
                      value={values.threadDescription}
                    />
                    <ButtonBig color="medium" onPress={onClose} title="Abbrechen" />
                    <ButtonBig color="success" onPress={handleSubmit}>
                      <MaterialCommunityIcons name="floppy" size={24} color={colors.white} style={styles.icon} />
                      <Text style={styles.text}>Absenden</Text>
                    </ButtonBig>
                  </>
                )}
              </Formik>
            </View>
          </ScrollView>
        </Screen>
      </KeyboardAvoidingView>
      <DoneScreen
        style={styles.upload}
        onDone={handleUploadComplete}
        visible={uploadVisible}
      />
    </Modal>
  );
}


const styles = StyleSheet.create({
  screen: {
    padding: 20,
    backgroundColor: colors.light,
  },
  view: {
    flex: 1, 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  headline: {
    fontSize: 24,
    fontWeight: "700",
    textAlign: "center",
    paddingTop: 50,
  },
  text: {
    color: colors.white,
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  icon: {
    marginHorizontal: 4,
  }
});

export default AddThreadScreen;
