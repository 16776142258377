import React, {useState, useEffect} from "react";
import { StyleSheet, View, TouchableOpacity, ScrollView, Platform, TextInput, ActivityIndicator } from "react-native";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useIsFocused } from '@react-navigation/native';
import axios from 'axios';

import { baseURL } from "../../api/client";
import useAuth from "../../auth/useAuth";
import colors from "../../config/colors";
import Screen from "../../components/Screen";
import Text from "../../components/Text";
import ThreadItem from "../../components/lists/ThreadItem";
import AddThreadScreen from "./AddThreadScreen";
import AddForumSuggestionScreen from "./AddForumSuggestionScreen";

function ThreadsScreen({ route, navigation }) {
  const { user } = useAuth();

  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { forumTitle, forumID } = route.params;
  const [threadsData, setThreadsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isFocused = useIsFocused();
  const [forumNotification, setForumNotification] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isModal2Visible, setModal2Visible] = useState(false);

  useEffect(() => {
    const fetchThreadsData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${baseURL}/threads/getThreads/${forumID}`);
        setThreadsData(response.data);
      } catch (error) {
        console.error("Fehler beim Abrufen der Themen:", error);
      }
      try {
        const response = await axios.get(`${baseURL}/favorites/getForumFavorite/${forumID}/${user.userID}`);
        setForumNotification(response.data ? true : false);
      } catch (error) {
          if (error.response && error.response.status === 404) {
              // Wenn kein Favorit gefunden wurde, setzen Sie forumNotification auf false
              setForumNotification(false);
          } else {
              // Für alle anderen Fehler
              console.error("Fehler beim Abrufen der Favorites:", error);
          }
      }
      setIsLoading(false);
    };

    fetchThreadsData();
  }, [isFocused, isModalVisible]);

  const fetchThreadsData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseURL}/threads/getThreads/${forumID}`);
      setThreadsData(response.data);
    } catch (error) {
      console.error("Fehler beim Abrufen der Themen:", error);
    }
    setIsLoading(false);
  };

  const handleNotification = async () => {
    if(!forumNotification){
      try {
        const response = await axios.post(`${baseURL}/favorites/addForumFavorite/${forumID}/${user.userID}`);
      } catch (error) {
        console.error("Fehler beim Abrufen der Themen:", error);
      }
    }else{
      try {
        const response = await axios.delete(`${baseURL}/favorites/deleteForumFavorite/${forumID}/${user.userID}`);
      } catch (error) {
        console.error("Fehler beim Abrufen der Themen:", error);
      }
    }
    setForumNotification(!forumNotification);
  };

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };
  const toggleModal2 = () => {
    setModal2Visible(!isModal2Visible);
  };
  const renderNoThreadsMessage = () => (
    <View style={styles.centeredMessage}>
      <Text style={styles.messageText}>Es gibt noch keine Themen in diesem Forum.</Text>
      <Text style={styles.messageText}>Sei der Erste, der ein Thema erstellt!</Text>
      <Text style={styles.messageText}>Deine Meinung zählt und kann anderen helfen.</Text>
    </View>
  );
  const handleThreadAdded = () => {
    fetchThreadsData(); // Daten neu laden, um die aktualisierte Liste anzuzeigen
  };
  const getFilteredThreads = () => {
    if (!searchText) return threadsData; // Wenn kein Suchtext vorhanden ist, geben Sie alle Threads zurück
  
    return threadsData.filter((thread) =>
      thread.threadTitle.toLowerCase().includes(searchText.toLowerCase()) ||
      thread.threadPreview.toLowerCase().includes(searchText.toLowerCase())
    );
  }; 

  return (
    <Screen style={styles.screen}>
      <View style={styles.headlineContainer}>
        <View style={{ flex: 1, marginRight: 10 }}>
          <Text style={[styles.header, { flexShrink: 1 }]}>
            {forumTitle}
          </Text>
        </View>
        {!forumNotification?
          <TouchableOpacity style={styles.bell} onPress={handleNotification} >
            <MaterialCommunityIcons name="bell-outline" color={colors.dark} size={26} />
          </TouchableOpacity>
          :
          <TouchableOpacity style={styles.bell} onPress={handleNotification} >
            <MaterialCommunityIcons name="bell-check" color={colors.success} size={26} />
          </TouchableOpacity>
        }
      </View>
      <View style={styles.search}>
        {!isSearchVisible && <View style={{flex: 1}} />} 
        {isSearchVisible && (
          <View style={styles.searchInputContainer}>
            <TextInput
              style={styles.searchInput}
              value={searchText}
              onChangeText={setSearchText}
              placeholder="Suche..."
            />
            <MaterialCommunityIcons
              name="close"
              color={colors.dark}
              size={25}
              onPress={() => {
                setIsSearchVisible(false);  // Versteckt das Suchfeld und löscht den Text, wenn auf das "X" geklickt wird
                setSearchText("");
              }}
            />
          </View>
        )}
        <MaterialCommunityIcons
          style={styles.searchIcon}
          name="magnify"
          color={colors.dark}
          size={25}
          onPress={() => setIsSearchVisible(true)}  // Zeigt das Suchfeld an, wenn auf das Symbol geklickt wird
        />
      </View>
      {isLoading ? (
        // Zeige den Loader an, wenn Daten geladen werden
        <View style={styles.loaderContainer}>
          <ActivityIndicator size="large" color={colors.primary} />
        </View>
      ) : (
        <>
          {threadsData.length > 0 ? (
            <ScrollView style={styles.view}>
              {getFilteredThreads().map((thread, index) => (
                <ThreadItem
                  key={index}
                  currentUserID={user.userID} 
                  title={thread.threadTitle}
                  threadUserID={thread.userID}
                  createdBy={thread.user ? thread.user.userProfileName : 'Unbekannter Benutzer'}
                  createdAt={thread.inserted_at} // Angenommen, das ist das Erstellungsdatum
                  commentsCount={thread.commentsCount}
                  likesCount={thread.likesCount} // Anzahl der Likes
                  preview={thread.threadPreview} // Vorschautext
                  onPress={() => console.log('Thread selected:', thread.threadTitle)}
                  onReadPress={() => navigation.navigate('Thema', { title: thread.threadTitle, threadID: thread.threadID })}
                  titleColor={thread.titleColor}
                  isLast={index === threadsData.length - 1}
                />
              ))}
            </ScrollView>
          ) : (
            renderNoThreadsMessage()
          )}
        </>
      )}
      <TouchableOpacity style={styles.fab2} onPress={toggleModal2} >
        <MaterialCommunityIcons name="lightbulb-on-outline" color={colors.white} size={26} />
      </TouchableOpacity>
      <AddForumSuggestionScreen isVisible={isModal2Visible} onClose={toggleModal2} />
      <TouchableOpacity style={styles.fab} onPress={toggleModal} >
        <MaterialCommunityIcons name="plus" color={colors.white} size={26} />
        <AddThreadScreen 
          isVisible={isModalVisible} 
          onClose={toggleModal} 
          forumID={forumID} 
          onThreadAdded={handleThreadAdded}
        />
      </TouchableOpacity>
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    backgroundColor: colors.light,
    paddingTop: 10,
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    margin: 15,
  },
  threadTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  threadTitleOne: {
    color: colors.info,
    fontSize: 16,
    fontWeight: 'bold',
  },
  threadDetails: {
    fontSize: 14,
    color: colors.medium,
  },
  fab: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    backgroundColor: colors.primary,
    borderRadius: 50,
    width: 56,
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
  },
  fab2: {
    position: 'absolute',
    left: 10,
    bottom: 10,
    backgroundColor: colors.warning,
    borderRadius: 50,
    width: 56,
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
  },
  view: {
    marginBottom: 75,
  },
  search: {
    flexDirection: "row",
    justifyContent: "space-between",  // Stellt sicher, dass das Suchsymbol immer rechts ist
    alignItems: "center",
    paddingHorizontal: 10,
    paddingTop: Platform.OS === 'android' ? 0 : 15,
    paddingBottom: 15,
    // ... andere Styles nach Bedarf
  },
  searchInputContainer: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    borderWidth: 1,
    borderColor: colors.dark,
    borderRadius: 5,
    paddingHorizontal: 10,
  },
  headlineContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  searchInput: {
    flex: 1,
    paddingRight: 100,
  },
  searchIcon: {
    paddingLeft: 10,
  },
  centeredMessage: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  messageText: {
    fontSize: 18,
    textAlign: 'center',
    color: 'grey',
    marginBottom: 10,
  },
});

export default ThreadsScreen;
