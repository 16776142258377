import React, { useEffect, useState, useRef } from "react";
import { StyleSheet, View, TouchableOpacity, Text, FlatList, TextInput, ActivityIndicator, KeyboardAvoidingView, Platform  } from "react-native";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useIsFocused } from '@react-navigation/native';
import axios from 'axios';
import moment from 'moment';
import { Left } from 'react-native-component-separator';

import { baseURL } from "../../api/client";
import useAuth from "../../auth/useAuth";
import colors from "../../config/colors";
import Screen from "../../components/Screen";
import PatchThreadScreen from "./PatchThreadScreen";

function ThreadScreen({ route }) {
  const { user } = useAuth();
  const { threadID } = route.params;
  const isFocused = useIsFocused();
  const commentInputRef = useRef(null);
  const flatListRef = useRef(null);

  const [threadData, setThreadData] = useState(null);
  const [liked, setLiked] = useState(false);
  const [commentContent, setCommentContent] = useState("");
  const [commentsData, setCommentsData] = useState(null);
  const [countComments, setCountComments] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const [isModalVisible, setModalVisible] = useState(false);
  const [editingVisible, setEditingVisible] = useState(false);
  const [editingData, setEditingData] = useState();
  const [likesCount, setLikesCount] = useState();
  const [threadNotification, setThreadNotification] = useState(false);

  useEffect(() => {
    const fetchThreadData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${baseURL}/threads/getThread/${threadID}`);
        setThreadData(response.data);
        setLikesCount(response.data.likesCount);
      } catch (error) {
        console.error("Fehler beim Abrufen des Beitrags:", error);
      }

      try {
        const response = await axios.get(`${baseURL}/favorites/getThreadFavorite/${threadID}/${user.userID}`);
        setThreadNotification(response.data ? true : false);
      } catch (error) {
          if (error.response && error.response.status === 404) {
              // Wenn kein Favorit gefunden wurde, setzen Sie forumNotification auf false
              setThreadNotification(false);
          } else {
              // Für alle anderen Fehler
              console.error("Fehler beim Abrufen der Favorites:", error);
          }
      }
      setIsLoading(false);
    };

    const fetchCommentsData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${baseURL}/comments/getComments/${threadID}`);
        setCommentsData(response.data);
        setCountComments(response.data.length);
      } catch (error) {
        console.error("Fehler beim Abrufen der Kommentare:", error);
      }
      setIsLoading(false);
    };

    const fetchLike = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${baseURL}/likes/getLike/${threadID}/${user.userID}`);
        if(response.data) setLiked(true); else setLiked(false);
      } catch (error) {
        console.error("Fehler beim Abrufen der Likes:", error);
      }
      setIsLoading(false);
    };

    if (isFocused) {
      fetchThreadData();
      fetchCommentsData();
      fetchLike();
    }
  }, [isFocused, threadID]);

  const fetchCommentsData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseURL}/comments/getComments/${threadID}`);
      setCommentsData(response.data);
      setCountComments(response.data.length);
    } catch (error) {
      console.error("Fehler beim Abrufen der Kommentare:", error);
    }
    setIsLoading(false);
  };

  const handleSendComment = async () => {
    if(!commentContent==''){
      try {
        let userID=user.userID;
        console.log(threadID, userID, commentContent);
        const response = await axios.post(`${baseURL}/comments/addComment`, {threadID, userID, commentContent});
        fetchCommentsData();
        setCommentContent("");
      } catch (error) {
        console.error("Fehler beim Senden des Kommentars:", error);
      }
    }
  };

  const handleLike = async () => {
    try {
        let response;
        if (!liked) {
            // Entferne Like - sende eine DELETE-Anfrage an dein Backend
            response = await axios.post(`${baseURL}/likes/addLike/${threadID}/${user.userID}`);
            // Aktualisiere den Zustand, um den Like zu entfernen
            setLiked(true);
            setLikesCount(likesCount+1);
            setThreadData({ ...threadData, hasLiked: false, likesCount: threadData.likesCount + 1 });
        } else {
            // Setze Like - sende eine POST-Anfrage an dein Backend
            response = await axios.delete(`${baseURL}/likes/deleteLike/${threadID}/${user.userID}`);
            // Aktualisiere den Zustand, um den Like hinzuzufügen
            setLiked(false);
            setLikesCount(likesCount-1);
            setThreadData({ ...threadData, hasLiked: true, likesCount: threadData.likesCount - 1 });
        }
        console.log('Like-Status geändert', response.data);
    } catch (error) {
        console.error('Fehler beim Ändern des Like-Status:', error);
    }
  };

  const handlePatch = async () => {
    setEditingVisible(true); // Angenommen, Sie haben eine State-Variable namens `setEditingVisible`
    setEditingData(threadData);
  }

  const handleUpdateThread = async (updatedData) => {
    try {
      const response = await axios.patch(`${baseURL}/threads/patchThread/${threadID}`, updatedData);
      setThreadData(response.data); // Aktualisieren Sie den Zustand mit den neuen Thread-Daten
      setEditingVisible(false); // Schließen Sie die Bearbeitungsmodal
    } catch (error) {
      console.error("Fehler beim Aktualisieren des Themas:", error);
      // Fehlerbehandlung
    }
  };

  const handleNotification = async () => {
    if(!threadNotification){
      try {
        const response = await axios.post(`${baseURL}/favorites/addThreadFavorite/${threadID}/${user.userID}`);
      } catch (error) {
        console.error("Fehler beim Abrufen der Themen:", error);
      }
    }else{
      try {
        const response = await axios.delete(`${baseURL}/favorites/deleteThreadFavorite/${threadID}/${user.userID}`);
      } catch (error) {
        console.error("Fehler beim Abrufen der Themen:", error);
      }
    }
    setThreadNotification(!threadNotification);
  };

  const handleInputFocus = () => {
    flatListRef.current?.scrollToEnd({ animated: true });
  };
  

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 100 : 0}
    >
      <Screen style={styles.screen}>
        <FlatList
          ListHeaderComponent={
            <>
              {threadData && (
                <>
                  {isLoading ? (
                    // Zeige den Loader an, wenn Daten geladen werden
                    <View style={styles.loaderContainer}>
                      <ActivityIndicator size="large" color={colors.primary} />
                    </View>
                  ) : (
                    <View style={styles.headline}>
                      <View style={styles.headlineContainer}>
                        <View style={{ flex: 1, marginRight: 10 }}>
                          <Text style={[styles.header, { flexShrink: 1 }]}>
                            {threadData.threadTitle}
                          </Text>
                        </View>
                        {user.userID === threadData.userID ? 
                            <TouchableOpacity onPress={handlePatch} style={styles.patchButton}>
                              <MaterialCommunityIcons
                                name='pencil-outline'
                                size={25}
                                color={colors.primary}
                              />
                            </TouchableOpacity>
                            :
                            (
                              !threadNotification ?
                                <TouchableOpacity style={styles.bell} onPress={handleNotification} >
                                  <MaterialCommunityIcons name="bell-outline" color={colors.dark} size={26} />
                                </TouchableOpacity>
                                :
                                <TouchableOpacity style={styles.bell} onPress={handleNotification} >
                                  <MaterialCommunityIcons name="bell-check" color={colors.success} size={26} />
                                </TouchableOpacity>
                            )
                        }
                      </View>
                      <View style={styles.likeSection}>
                        <Text style={styles.likeText}><MaterialCommunityIcons name="heart" size={20} color="red" /> ({likesCount})</Text>

                        <TouchableOpacity onPress={handleLike} style={styles.likeButton}>
                          {!liked && <Text style={styles.likeButtonText}>liken</Text>}
                          <MaterialCommunityIcons
                            name={liked ? "heart" : "heart-outline"}
                            size={20}
                            color={liked ? "red" : "grey"}
                          />
                        </TouchableOpacity>
                        
                      </View>
                        <Text style={styles.info}>
                          Erstellt von {user.userID==threadData.userID? 'mir' : threadData.user.userProfileName} | am {moment(threadData.inserted_at).format('DD.MM.YYYY')}
                        </Text>
                        
                        <Text style={styles.preview}>{threadData.threadPreview}</Text>
                        <Text style={styles.body}>{threadData.threadDescription}</Text>
                    </View>
                  )}
                </>
              )}
              <Left borderColor="#e0e0e0" color="#616161" >{countComments} Kommentare</Left> 
            </>
          }
          data={commentsData}
          keyExtractor={(item) => item.commentID.toString()}
          style={styles.flatList}
          ref={flatListRef}
          // onContentSizeChange={() => flatListRef.current?.scrollToEnd({ animated: true })}
          renderItem={({ item }) => (
            <>
              {isLoading ? (
                <View style={styles.loaderContainer}>
                  <ActivityIndicator size="large" color={colors.primary} />
                </View>
              ) : (
                <View style={[styles.messageContainer, item.userID === user.userID ? styles.myMessageContainer : styles.otherMessageContainer]}>
                  <View style={item.userID === user.userID ? styles.myMessageBubble : styles.otherMessageBubble}>
                    {/* Prüfe, ob das user-Objekt vorhanden ist und zeige Datum/Uhrzeit an */}
                    <View style={styles.commentHeader}>
                      <Text style={[styles.commentDate, item.userID === user.userID ? styles.myCommentDate : null]}>
                        {item.user ? item.user.userProfileName : 'Anonym'}
                      </Text>
                      <Text style={[styles.commentDate, item.userID === user.userID ? styles.myCommentDate : null]}>
                        {moment(new Date(item.inserted_at)).format('DD.MM.YYYY HH:mm')}
                      </Text>
                    </View>
                    <Text style={[styles.messageText, item.userID === user.userID ? styles.myMessageText : null]}>
                      {item.commentContent}
                    </Text>
                  </View>
                </View>
              )}
            </>
          )}
            ListFooterComponent={   
              <>
                <View style={styles.commentInputContainer}>
                  <TextInput
                    onFocus={handleInputFocus}
                    ref={commentInputRef}
                    style={styles.commentInput}
                    value={commentContent}
                    onChangeText={setCommentContent}
                    placeholder="Einen Kommentar hinzufügen..."
                    multiline
                  />
                  <TouchableOpacity onPress={handleSendComment} style={styles.sendButton}>
                    <MaterialCommunityIcons name="send" color={colors.white} size={26} />
                  </TouchableOpacity>
                </View>
              </>
            }
          />
        <PatchThreadScreen 
          isVisible={editingVisible} 
          onClose={() => setEditingVisible(false)}
          initialData={editingData}
          onSubmit={handleUpdateThread}
          threadID={threadID} // Übergeben Sie threadID als Prop
        />
      </Screen>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  screen: {
    padding: 20,
    backgroundColor: colors.light,
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  headlineContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  // headlineContent: {
  //   flexDirection: 'row', // Elemente nebeneinander anordnen
  //   alignItems: 'center', // Vertikal zentrieren
  //   justifyContent: 'space-between',
  //   paddingVertical: 10,
  // },
  body: {
    fontSize: 16,
    marginBottom: 10,
  },
  messageContainer: {
    marginBottom: 10,
    flexDirection: 'row',
  },
  myMessageContainer: {
    justifyContent: 'flex-end',
  },
  otherMessageContainer: {
    justifyContent: 'flex-start',
  },
  myMessageBubble: {
    width: '80%',
    backgroundColor: colors.success,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: 10,
  },
  otherMessageBubble: {
    width: '80%',
    backgroundColor: colors.white,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: 10,
  },
  messageText: {
    fontSize: 16,
    color: colors.black,
  },
  myMessageText: {
    color: colors.white,
  },
  fab: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    backgroundColor: colors.primary,
    borderRadius: 50,
    width: 56,
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
  },
  threadMessage: {
    paddingBottom: 30,
  },
  myMessageSender: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.light,
    paddingBottom: 10,
  },
  otherMessageSender: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.primary,
    paddingBottom: 10,
  },
  preview: {
    fontSize: 14,
    color: colors.medium,
    marginBottom: 10,
  },
  info: {
    fontSize: 12,
    color: colors.medium,
    marginBottom: 10,
    paddingTop: 10,
  },
  likeButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  likeText: {
    marginLeft: 5,
    fontSize: 14,
    color: colors.medium,
  },
  commentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
  },
  commentDate: {
    fontSize: 12,
    color: colors.medium,
  },
  myCommentDate: {
    color: colors.white,
  },
  likeSection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  likeButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  likeButtonText: {
    marginLeft: 5,
  },
  commentInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    marginBottom: 20
  },
  commentInput: {
    flex: 1,
    borderWidth: 1,
    borderColor: colors.medium,
    borderRadius: 25,
    padding: 15,
    marginRight: 10,
    textAlignVertical: 'center'
  },
  placeholderText: {
    color: 'grey',
  },
  sendButton: {
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary,
    borderRadius: 25,
  },
  separator: {
    height: 1,
    backgroundColor: colors.medium,
    width: '100%',
    alignSelf: 'center',
    marginVertical: 10,
  },
  flatList: {
    paddingVertical: 20,
  },
});

export default ThreadScreen;
