import React from "react";
import { StyleSheet, View, ScrollView, Image, Linking } from "react-native";
import { MaterialCommunityIcons } from '@expo/vector-icons';

import colors from "../../config/colors";
import Screen from "../../components/Screen";
import Text from "../../components/Text";
import ButtonBig from "../../components/ButtonBig";

import routes from "../../navigation/routes";


function TipsScreen({navigation}) {

  return (
    <Screen style={styles.screen}>
      <ScrollView>
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Mobilität</Text>
          <Text style={styles.sectionContent}>
            Alles rund ums Parken:
          </Text>
          <Text style={styles.sectionContent}>
          Der Schwerbehindertenausweis allein ist kein Parkausweis. Bei den Merkzeichen G, aG, H, Gl sowie BL ist man berechtigt einen blauen Parkausweis bei der Gemeinde zu beantragen.
          </Text>
          <Text style={styles.sectionContent}>
          Dieser hat folgende Vorteile:
          </Text>
          <Text style={styles.listItem}>
            <MaterialCommunityIcons name="square-small" size={20} />
            man kann bis zu 3 Stunden im eingeschränkten Halteverbot parken (wichtig: Parkscheibe einstellen und sichtbar ins Auto legen)
          </Text>
          <Text style={styles.listItem}>
            <MaterialCommunityIcons name="square-small" size={20} />
            bei "Parken erlaubt, aber mit Zeitbegrenzung" ist die Zeitbegrenzung aufgehoben
          </Text>
          <Text style={styles.listItem}>
            <MaterialCommunityIcons name="square-small" size={20} />
            in Fußgängerzonen: wenn Be,-und Entladezeiten freigegeben sind, kann während den Ladezeiten geparkt werden
          </Text>
          <Text style={styles.listItem}>
            <MaterialCommunityIcons name="square-small" size={20} />
            auf Anwohnerparkplätzen kann man bis zu 3 Stunden parken
          </Text>
          <Text style={styles.listItem}>
            <MaterialCommunityIcons name="square-small" size={20} />
            bei Parkuhren kann man ohne Gebühr und ohne Zeitbegrenzung parken
          </Text>
          <Text style={styles.listItem}>
            <MaterialCommunityIcons name="square-small" size={20} />
            Berechtigung für Behindertenparkplatz
          </Text>
          <Text style={styles.sectionContent}>
            Zugfahren
          </Text>
          <Text style={styles.sectionContent}>
            Das Merkzeichen B berechtigt eine Begleitperson kostenlos mitzunehmen. Ob die Voraussetzungen dafür vorliegen, prüft das Versorgungsamt.
            Schwerbehinderte Menschen können kostenlos mit Bus und Bahn (auch ICE 1. Klasse) fahren. Wichtig: Im Versorgungsamt muss eine extra Wertmarke beantragt werden. Bei h (Hilfslosigkeit) und b (blind) ist die Wertmarke kostenfrei zu beantragen.
          </Text>
        </View>

        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Einkaufen</Text>
          <Text style={styles.sectionContent}>
            In einigen Supermärkten gibt es die Stille Stunde.
          </Text>
          <Text style={styles.sectionContent}>
            Während dieser Zeit soll auf Lautsprecherdurchsagen und Musik verzichtet werden. Außerdem werden in dieser Zeit die Lichter gedimmt, Kassentöne auf das Mindeste reduziert und keine Regale eingeräumt.
          </Text>
        </View>

        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Alltag</Text>
          <Text style={styles.sectionContent}>
            Öffentliche Toiletten:
          </Text>
          <Text style={styles.sectionContent}>
            Den Euroschlüssel für öffentliche Toiletten erhalten Personen, die in ihrem Schwerbehindertenausweis entweder – unabhängig vom Grad der Behinderung – eines der Merkzeichen aG, B, H, Bl eingetragen haben oder das Merkzeichen G und einen Grad der Behinderung (GdB) von mindestens 70 eingetragen haben.
            Bestellen kann man den Schlüssel für 26,90€ bei cbf-da.de/euroschluessel.html.  Ferner gibt es dort auch das Verzeichnis „Der Locus“, in dem in der Auflage aus dem Jahr 2017 über 12.000 Toilettenstandorte in Deutschland und Europa verzeichnet sind.
          </Text>
        </View>

        <View style={styles.sectionContainer}>
          <Text style={styles.sectionTitle}>Beratungsstellen</Text>
          <Text style={styles.sectionContent}>
            Öffentliche Toiletten:
          </Text>
          <Text style={styles.sectionContent}>
          EUTB: Ergänzende unabhängige Teilhabeberatung
          </Text>
          <Text style={styles.sectionContent}>
          Deutschlandweite Beratungsstelle informiert über Teilhabeleistungen, wie zb das Persönliche Budget
          </Text>
          <Text style={styles.sectionContent}>
          Telefonseelsorge: Per Telefon 0800 / 111 0 111 , 0800 / 111 0 222 oder 116 123
          </Text>
          <Text style={styles.sectionContent}>
          per Mail und Chat unter online.telefonseelsorge.de
          </Text>
        </View>
        <ButtonBig color="success" onPress={() => navigation.navigate(routes.FORUM)}>
          <MaterialCommunityIcons name="human-male-female-child" size={20} color={colors.white} style={styles.icon} />
          <Text style={styles.text}>Zum Forum</Text>
        </ButtonBig>
      </ScrollView>
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    padding: 10,
  },
  headerContainer: {
    // Ihr Styling
  },
  text: {
    color: colors.white,
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  icon: {
    marginHorizontal: 4,
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  headerSubtitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  sectionContainer: {
    paddingVertical: 20,
  },
  sectionTitle: {
    fontSize: 26,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  sectionContent: {
    marginVertical: 20,
    fontSize: 16,
  },
  image: {
    width: '100%',
    height: 300,
    resizeMode: 'cover',
  },
  downloadButton: {
    // Ihr Styling
  },
  downloadButtonText: {
    fontSize: 16,
    color: colors.primary,
  },
  listItem:{
    flexDirection: "row",
    paddingBottom: 10,
  }
});


export default TipsScreen;

