import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import Text from "../Text";
import colors from "../../config/colors";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import moment from 'moment'; // Stelle sicher, dass moment in deinem Projekt installiert ist

function ThreadItem({ title, createdBy, createdAt, likesCount, preview, onPress, onReadPress, titleColor, isLast, currentUserID, threadUserID, commentsCount }) {
  const isCreatedByCurrentUser = threadUserID === currentUserID;
  
  return (
    <TouchableOpacity onPress={onPress} style={[styles.container, isLast && { marginBottom: 20 }]}>
      <View style={styles.textContainer}>
        <View style={styles.titleAndLikesContainer}>
          <Text style={[styles.title, { color: titleColor || colors.black }]}>{title}</Text>
          <View style={styles.likesContainer}>
            <MaterialCommunityIcons name="heart" size={16} color="red" />
            <Text style={styles.likesText}>{` ${likesCount}`}</Text>
          </View>
        </View>
        <Text style={[styles.detail, isCreatedByCurrentUser && { color: colors.primary }]}>
          {isCreatedByCurrentUser ? 'von mir' : `von ${createdBy}`} | erstellt am: {moment(new Date(createdAt)).format('DD.MM.YYYY')}
        </Text>
        <Text style={styles.comments}>{commentsCount} Kommentare</Text>
        <Text style={styles.preview}>{preview}</Text>
      </View>
      <View style={styles.buttonContainer}>
        <TouchableOpacity style={styles.readButton} onPress={onReadPress}>
          <Text style={styles.readButtonText}>lesen</Text>
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    padding: 15,
    backgroundColor: colors.white,
    marginVertical: 10,
    borderRadius: 10,
  },
  textContainer: {
    flex: 1,
    paddingRight: 10, // added some padding to avoid text overlapping with the button
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
  },
  detail: {
    fontSize: 14,
    color: colors.medium,
  },
  comments: {
    fontSize: 14,
    fontWeight: '500'
  },
  buttonContainer: {
    justifyContent: 'flex-end',  // Align the button to the bottom
  },
  readButton: {
    borderWidth: 1,
    borderColor: colors.medium,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    paddingHorizontal: 10,
  },
  readButtonText: {
    color: colors.dark,
    fontSize: 14,
  },
  preview: {
    fontSize: 14,
    color: colors.medium,
    paddingTop: 10
  },
  titleAndLikesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  likesContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  likesText: {
    fontSize: 14,
    color: colors.medium,
  },
});

export default ThreadItem;
