import React, {useState} from "react";
import { Formik, Field } from 'formik';
import Modal from "react-native-modal";
import axios from "axios";

import { StyleSheet, View, ScrollView, KeyboardAvoidingView, Platform } from "react-native";
import { MaterialCommunityIcons } from '@expo/vector-icons';

import DoneScreen from "../../components/DoneScreen";

import { baseURL } from "../../api/client";
import useAuth from "../../auth/useAuth";
import colors from "../../config/colors";
import Screen from "../../components/Screen";
import Text from "../../components/Text";
import ButtonBig from "../../components/ButtonBig";
import { TextInput } from "../../components/forms";


function AddHolidaySuggestionScreen({ isVisible, onClose }) {
  const { user } = useAuth();
  const [uploadVisible, setUploadVisible] = useState(false);

  const handleUploadComplete = () => {
      setUploadVisible(false);
      onClose();  
  };
  
  const handleSubmit = async (values) => {
    if(!values.answer==''){
      try {
        let answer=values.answer;
        let userMail=user.userMail;
        await axios.post(`${baseURL}/mails/sendForumSuggestion/${userMail}`, {answer});
      } catch (error) {
        console.error("Fehler beim Senden der Mail:", error);
      }
    }
    setUploadVisible(true);
  };


  return (
    <Modal isVisible={isVisible} style={{ margin: 0 }}>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 0}
      >
        <Screen style={styles.screen}>
          <ScrollView>
            <View style={styles.textView}>
              <Text style={styles.title}>Vermisst du eine tolle Reiseoption?</Text>
              <Text style={styles.subTitle}>Reiche deinen Vorschlag ein. Wir sind schon ganz gespannt, wo die nächste Reise hingehen soll.</Text>
            </View>
            <View style={styles.view}>
              <Formik
                initialValues={{ answer: '' }}  // initial leeres Textfeld
                onSubmit={handleSubmit}
              >
                {({ handleChange, handleSubmit, values }) => (
                  <>
                    <Field 
                      component={TextInput}
                      name="answer"
                      placeholder="Schreibe einfach das, was dir gerade einfällt :)"
                      multiline={true}
                      numberOfLines={10}
                      onChangeText={handleChange('answer')}
                      value={values.answer}
                    />
                    <ButtonBig color="medium" onPress={onClose} title="Abbrechen" />
                    <ButtonBig color="primary" onPress={handleSubmit}>
                      <MaterialCommunityIcons name="send" size={24} color={colors.white} style={styles.icon} />
                      <Text style={styles.text}>Absenden</Text>
                    </ButtonBig>
                  </>
                )}
              </Formik>
            </View>
          </ScrollView>
        </Screen>
      </KeyboardAvoidingView>
      <DoneScreen
        style={styles.upload}
        onDone={handleUploadComplete}
        visible={uploadVisible}
      />
    </Modal>
  );
}


const styles = StyleSheet.create({
  screen: {
    padding: 20,
    backgroundColor: colors.light,
  },
  view: {
    flex: 1, 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  text: {
    color: colors.white,
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  icon: {
    marginHorizontal: 4,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  upload: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
    backgroundColor: 'transparent',  // Hinzugefügt, um einen transparenten Hintergrund zu gewährleisten
  },
  textView: {
    textAlign: "center",
    paddingTop: 50,
  },
});

export default AddHolidaySuggestionScreen;
