import React, { useState, useEffect } from "react";
import { useIsFocused } from '@react-navigation/native';
import axios from 'axios';
import { StyleSheet, View, Button, Text } from "react-native";

import { baseURL } from "../../api/client";
import Screen from "../../components/Screen";
import useAuth from "../../auth/useAuth";
import ButtonBig from "../../components/ButtonBig";

function DataScreen({ navigation }) {
  const { user, getToken } = useAuth();
  const [userData, setUserData] = useState({});
  const isFocused = useIsFocused(); // Zustand für den Fokus des Bildschirms

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const accessToken = await getToken();
        const response = await axios.get(`${baseURL}/users/getUser/${user.userID}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        setUserData(response.data);
        } catch (error) {
          if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            try {
              // Senden des Requests an den /refreshToken-Endpoint
              const refreshResponse = await axios.post('${baseURL}/refreshToken', {}, { 
                withCredentials: true // Stellen Sie sicher, dass Cookies gesendet werden
              });
              const newAccessToken = refreshResponse.data.accessToken;
              await getToken(newAccessToken); // Speichern des neuen AccessTokens
              fetchUserData(); // Erneuter Versuch, die Nutzerdaten abzurufen
            } catch (refreshError) {
              console.error("Fehler bei der Token-Erneuerung:", refreshError);
              // Weiterführende Fehlerbehandlung...
            }
          } else {
            console.error("Fehler beim Abrufen der Nutzerdaten:", error);
            // Weiterführende Fehlerbehandlung...
          }
        }
    };

    if (isFocused) {
      fetchUserData();
    }
  }, [isFocused]);

  return (
    <Screen style={styles.screen}>
      <View style={styles.container}>
        <View style={styles.infoRow}>
          <Text style={styles.label}>Profilname:</Text>
          <Text style={styles.value}>{userData.userProfileName}</Text>
        </View>
        <View style={styles.infoRow}>
          <Text style={styles.label}>Vorname:</Text>
          <Text style={styles.value}>{userData.userFirstName}</Text>
        </View>
        <View style={styles.infoRow}>
          <Text style={styles.label}>Nachname:</Text>
          <Text style={styles.value}>{userData.userLastName}</Text>
        </View>
        <View style={styles.infoRow}>
          <Text style={styles.label}>Email:</Text>
          <Text style={styles.value}>{userData.userMail}</Text>
        </View>
        <View style={styles.infoRow}>
          <Text style={styles.label}>Passwort:</Text>
          <Text style={styles.value} secureTextEntry={true}>•••••</Text>
        </View>
        <ButtonBig title="Daten bearbeiten" onPress={() => navigation.navigate('Daten bearbeiten', { userData })} />
        <ButtonBig title="Passwort ändern" onPress={() => navigation.navigate('Passwort ändern', {userMail:userData.userMail,userProfileName:userData.userProfileName})} />
      </View>
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    padding: 10,
  },
  container: {
    marginVertical: 20,
  },
  infoRow: {
    flexDirection: 'row',
    marginVertical: 10,
  },
  label: {
    width: 100,
  },
  value: {
    marginLeft: 10,
  },
});

export default DataScreen;
