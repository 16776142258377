import React from "react";
import LottieView from "lottie-react-native";
import { View, StyleSheet, Platform, Modal } from "react-native";

function ActivityIndicator({ onDone, visible = false }) {
  if(Platform.OS === 'web')return;

  return (
    <Modal visible={visible}>
      <View style={styles.overlay}>
        <LottieView
          autoPlay
          loop={false}
          onAnimationFinish={onDone}
          source={require("../assets/animations/loader.json")}
          style={styles.animation}
        />
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  animation: {
    width: 200,
    height: 200,
  },

});

export default ActivityIndicator;

