import React, {useState} from "react";
import { StyleSheet, View, FlatList, TouchableOpacity } from "react-native";
import { MaterialCommunityIcons } from '@expo/vector-icons';

import HolidayContentCard from "../../components/HolidayContentCard";
import AddHolidaySuggestionScreen from "./AddHolidaySuggestionScreen";
import colors from "../../config/colors";
import Screen from "../../components/Screen";
import Text from "../../components/Text";

//images
import familienportalnrw from "../../assets/holiday/familienportalnrw.png";
import netreisetagebuch from "../../assets/holiday/netreisetagebuch.png";
import ganzschoenlaut from "../../assets/holiday/ganzschoenlaut.png";

class LinkInfo {
  constructor(url, thumbnail, title, previewText, commentsCount, rating, voteCount) {
    this.url = url;
    this.thumbnail = thumbnail;
    this.title = title;
    this.previewText = previewText;
    this.commentsCount = commentsCount;
    this.rating = rating;
    this.voteCount = voteCount;
  }
}
const linkData = [
  {
    state: "Allgemeine Infos / Erfahrungsberichte",
    links: [
      new LinkInfo(
        "https://familienportal.nrw/de/leben-mit-behinderung/urlaub-mit-behindertem-kind",
        familienportalnrw,
        "Familienportal NRW",
        "Die Seite bietet Ratschläge und Unterstützung für Familien mit behinderten Kindern, die einen Urlaub planen. Sie enthält Informationen über behindertengerechte Urlaubsangebote, spezialisierte Reisevermittler, finanzielle Unterstützung für Urlaubsreisen von Menschen mit Behinderungen und wie man geeignete Ziele findet. ",
      ),
      new LinkInfo(
        "https://www.netreisetagebuch.de/reisen-mit-behindertem-kind/",
        netreisetagebuch,
        "Net Reisetagebuch",
        "Die Seite scheint eine Diskussion über die Herausforderungen beim Reisen mit einem behinderten Kind zu bieten, allerdings war es schwierig, spezifische Informationen oder Tipps aus dem Inhalt zu extrahieren.",
      ),
      new LinkInfo(
        "https://ganzschoenlaut.de/urlaub-mit-behindertem-kind-adhs-autismus-fasd/",
        ganzschoenlaut,
        "Ganz schön laut",
        "Der Blogpost teilt persönliche Erfahrungen und gibt Tipps für einen Urlaub mit behinderten Kindern, insbesondere solchen mit ADHS, Autismus oder FASD. Es wird eine positive Erfahrung über einen Urlaub auf der Gehwolfalm in Österreich geteilt, der besonders für geistig behinderte Kinder geeignet erscheint.",
      ),
    ]
  },
]

function HolidayContentScreen() {
  const [isModalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  return (
    <Screen style={styles.screen}>
      <View style={styles.view}>
        <FlatList
          data={linkData}
          keyExtractor={(item) => item.state}
          renderItem={({ item }) => (
            <View>
              <Text style={styles.subHeader}>{item.state}</Text>
                {item.links.map((link, index) => (
                  <HolidayContentCard
                      key={index}
                      link={link.url}
                      image={link.thumbnail}  // Hier wurde die Änderung vorgenommen
                      title={link.title}
                      description={link.previewText}
                  />
              ))}
            </View>
          )}
        />
      </View>
      <TouchableOpacity style={styles.fab} onPress={toggleModal} >
          <MaterialCommunityIcons name="lightbulb-on-outline" color={colors.white} size={26} />
          <AddHolidaySuggestionScreen isVisible={isModalVisible} onClose={toggleModal} />
      </TouchableOpacity>
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    padding: 10,
    backgroundColor: colors.light,
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 5,
  },
  link: {
    color: 'blue',
    textDecorationLine: 'underline',
    marginBottom: 5,
  },
  view: {
    paddingBottom: 30,
  },
  fab: {
    position: 'absolute',
    left: 10,
    bottom: 10,
    backgroundColor: colors.warning,
    borderRadius: 50,
    width: 56,
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default HolidayContentScreen;

