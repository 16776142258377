import React, { useState, useEffect } from "react";
import { useIsFocused } from '@react-navigation/native';
import axios from 'axios';
import { StyleSheet, View, Modal, Button, StatusBar, Platform, Alert } from "react-native";
import Text from "../../components/Text";

import { baseURL } from "../../api/client";
import colors from "../../config/colors";
import Screen from "../../components/Screen";
import useAuth from "../../auth/useAuth";
import ButtonBig from "../../components/ButtonBig";

import ActivityIndicator from "../../components/ActivityIndicator";

function AccountDetail() {
  const { user, logOut } = useAuth();
  const [userData, setUserData] = useState({});
  const isFocused = useIsFocused();
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${baseURL}/users/getUserAccount/${user.userID}`);
        setUserData(response.data);
        } catch (error) {
          console.error("Fehler beim Holen der Daten:", error);
          Alert.alert("Fehler", "Ein unerwarteter Fehler ist aufgetreten");
        }
    };

    if (isFocused) {
      fetchUserData();
    }
  }, [isFocused]);
  
  function formatGermanDate(isoDateString) {
    const date = new Date(isoDateString);
    return date.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  const handleLogOut = async () => {
    setIsLoggingOut(true);
    // Künstliche Verzögerung für die Ausloggen-Animation
    setTimeout(() => {
      logOut();
      setIsLoggingOut(false);
    }, 3000); // Verzögern Sie das Ausloggen um 3 Sekunden
  };

  const deleteAccount = async () => {
    
    try {
      const deleteAccount = await axios.patch(`${baseURL}/users/deleteUserAccount/${user.userID}`);

      if (deleteAccount.status === 200) {
        Alert.alert("Erfolgreich gelöscht", "Dein Account wurde erfolgreich gelöscht. Du wirst nun abgemeldet", [
          {
            text: "OK",
            onPress: () => handleLogOut()
          }
        ]);
      } else {
        Alert.alert("Fehler", "Dein Account konnte nicht gelöscht werden!");
      }
    } catch (error) {
      console.error("Fehler beim Löschen deines Accounts:", error);
      Alert.alert("Fehler", "Ein unerwarteter Fehler ist aufgetreten");
    }
  };
  
  return (
    <Screen style={styles.screen}>
      <ActivityIndicator visible={isLoggingOut} />
      <View style={styles.container}>
        <Text>Name: {userData.userFirstName} {userData.userLastName}</Text>
        <Text>Account erstellt am: {userData.inserted_at ? formatGermanDate(userData.inserted_at) : 'Lädt...'}</Text>
        
        <ButtonBig title="Konto löschen" onPress={() => setModalVisible(true)} color="danger" />

        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text style={styles.modalText}>Möchtest du dein Konto wirklich löschen?</Text>
              <View style={styles.buttonContainer}>
                <Button 
                  title="Konto löschen" 
                  onPress={deleteAccount}
                  color={colors.danger}
                />
                <Button
                  title="Abbrechen" 
                  onPress={() => setModalVisible(!modalVisible)}
                  color={colors.secondary}
                />
              </View>
            </View>
          </View>
        </Modal>
      </View>
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    padding: 10,
  },
  container: {
    marginVertical: 20,
  },
  infoRow: {
    flexDirection: 'row',
    marginVertical: 10,
  },
  label: {
    width: 100,
  },
  value: {
    marginLeft: 10,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: Platform.OS === "android" ? -StatusBar.currentHeight : 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },   
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    zIndex: 1,  // Bringt das Modal über den Blur-Hintergrund
  },
  optionsContainer: {
    marginBottom: 20,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%', // oder ein spezifischer Wert, um den Platz zu bestimmen
  },
});

export default AccountDetail;
