import React, {useState, useEffect} from "react";
import { StyleSheet, View, FlatList, TouchableOpacity, ActivityIndicator } from "react-native";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import axios from "axios";
import { useIsFocused } from '@react-navigation/native';

import { baseURL } from "../../api/client";
import HolidayCard from "../../components/HolidayCard";
import colors from "../../config/colors";
import Screen from "../../components/Screen";
import Text from "../../components/Text";
import routes from "../../navigation/routes";
import AddHolidaySuggestionScreen from "./AddHolidaySuggestionScreen";


function HolidayGermanyScreen({ navigation }) {
  const [isLoading, setIsLoading] = useState(false);
  const isFocused = useIsFocused();
  const [isModalVisible, setModalVisible] = useState(false);
  const [worldHolidayData, setWorldHolidayData] = useState([]);
  const baseImageURL = `${baseURL}/images/holiday/`;

  useEffect(() => {
    const fetchWorldHolidaysData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${baseURL}/holidays/getWorldHolidays`);
        setWorldHolidayData(response.data);
        } catch (error) {
          console.error("Fehler beim Abrufen der WorldHoliday:", error);
        }
        setIsLoading(false);
    };

    if (isFocused) {
      fetchWorldHolidaysData();
    }
  }, [isFocused]);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  return (
    <Screen style={styles.screen}>
      <View style={styles.view}>
        <Text style={styles.header}>Thema Urlaub in Deutschland</Text>
        {isLoading ? (
          // Zeige den Loader an, wenn Daten geladen werden
          <View style={styles.loaderContainer}>
            <ActivityIndicator size="large" color={colors.primary} />
          </View>
        ) : (
          <FlatList
            data={worldHolidayData}
            keyExtractor={(item, index) => item.holidayID.toString()}
            renderItem={({ item }) => (
              <HolidayCard
                key={item.holidayID}
                link={item.holidayUrl}
                image={{ uri: `${baseImageURL}${item.holidayImage}` }}
                title={item.holidayTitle}
                description={item.holidayDescription}
                comments={item.commentsCount}
                rating={item.holidayRating}
                votes={item.voteCount}
                onPress={() => navigation.navigate(routes.HOLIDAYDETAILSCREEN, { holidayData: item, origin: 'HolidayWorldScreen' })}
              />
            )}
          />
        )}
      </View>
      <TouchableOpacity style={styles.fab} onPress={toggleModal} >
          <MaterialCommunityIcons name="lightbulb-on-outline" color={colors.white} size={26} />
          <AddHolidaySuggestionScreen isVisible={isModalVisible} onClose={toggleModal} />
      </TouchableOpacity>
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    padding: 10,
    backgroundColor: colors.light,
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 5,
  },
  link: {
    color: 'blue',
    textDecorationLine: 'underline',
    marginBottom: 5,
  },
  view: {
    paddingBottom: 30,
  },
  fab: {
    position: 'absolute',
    left: 10,
    bottom: 10,
    backgroundColor: colors.warning,
    borderRadius: 50,
    width: 56,
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default HolidayGermanyScreen;

