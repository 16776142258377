import React, {useState} from "react";
import { StyleSheet, Alert, View, Text, KeyboardAvoidingView, Platform } from "react-native";
import * as Yup from "yup";
import axios from "axios"; // Stellen Sie sicher, dass Axios importiert wird

import { baseURL } from "../../api/client";
import { Form, FormField, SubmitButton } from "../../components/forms";
import colors from "../../config/colors";
import Screen from "../../components/Screen";
import useAuth from "../../auth/useAuth";
import DoneScreen from "../../components/DoneScreen";

const validationSchema = Yup.object().shape({
  userProfileName: Yup.string().required().min(1).label("Profilname"),
  userFirstName: Yup.string().required().min(1).label("Vorname"),
  userLastName: Yup.string().required().min(1).label("Nachname"),
  userMail: Yup.string().required().email().label("Email"),
});

function EditDataScreen({ navigation, route }) {
  const { userData } = route.params;
  const { user } = useAuth(); // Benutzer aus dem Authentifizierungskontext extrahieren
  const [updateVisible, setUpdateVisible] = useState(false);

  const handleUpdateComplete = () => {
      setUpdateVisible(false);
  };

  const handleSubmit = async (values, { resetForm }) => {
    
    try {
      const updateResult = await axios.patch(`${baseURL}/users/patchUser/${user.userID}`, values);

      if (updateResult.status === 200) {
        setUpdateVisible(true);
      } else {
        Alert.alert("Fehler", "Daten konnten nicht aktualisiert werden");
      }
    } catch (error) {
      console.error("Fehler beim Aktualisieren der Daten:", error);
      Alert.alert("Fehler", "Ein unerwarteter Fehler ist aufgetreten");
    }
  };

  return (
    <Screen style={styles.container}>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 0}
      >
        <Form
          initialValues={{
            userProfileName: userData.userProfileName,
            userFirstName: userData.userFirstName,
            userLastName: userData.userLastName,
            userMail: userData.userMail,
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <View style={styles.label}><Text>Profilname</Text></View>
          <FormField name="userProfileName" placeholder="Profilname" />
          <View style={styles.label}><Text>Vorname</Text></View>
          <FormField name="userFirstName" placeholder="Vorname" />
          <View style={styles.label}><Text>Nachname</Text></View>
          <FormField name="userLastName" placeholder="Nachname" />
          <View style={styles.label}><Text>Email</Text></View>
          <FormField name="userMail" placeholder="Email" keyboardType="email-address" autoCapitalize="none" />
          <SubmitButton title="Speichern" color={colors.success} />
        </Form>
      </KeyboardAvoidingView>
      <DoneScreen
        style={styles.update}
        onDone={handleUpdateComplete}
        visible={updateVisible}
      />
    </Screen>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  label: {
    marginBottom: 5,
  },
  update: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
    backgroundColor: 'transparent',  // Hinzugefügt, um einen transparenten Hintergrund zu gewährleisten
  },
});

export default EditDataScreen;
