import React, { useState } from "react";
import { FontAwesome } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import axios from 'axios';
import { StyleSheet, Image, Alert, TouchableOpacity } from "react-native";
import * as Yup from "yup";

import { baseURL } from "../api/client";
import LoginScreen from "./LoginScreen";
import colors from "../config/colors";
import Text from "../components/Text";
import Screen from "../components/Screen";
import ButtonBig from "../components/ButtonBig"
import useAuth from "../auth/useAuth";
import {
  ErrorMessage,
  Form,
  FormField,
  SubmitButton,
} from "../components/forms";
import ActivityIndicator from "../components/ActivityIndicator";

const checkProfileNameUnique = async (userProfileName) => {
  try {
    const response = await axios.get(`${baseURL}/users/checkUserProfileName/${userProfileName}`);
    return !response.data.isUnique; // Angenommen, die API gibt ein Objekt mit { isUnique: true/false } zurück
  } catch (error) {
    console.error("Fehler beim Überprüfen des Profilnamens", error);
    return false;
  }
};
const validationSchema = Yup.object().shape({
  userProfileName: Yup.string()
    .required("Profilname ist erforderlich")
    .test(
      "is-unique",
      "Der Profilname ist bereits vergeben",
      async (value) => await checkProfileNameUnique(value)
    ),
  userFirstName: Yup.string().label("Vorname"),
  userLastName: Yup.string().label("Nachname"),
  userMail: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(4).label("Password"),
});

function RegisterScreen({navigation}) {
  const [isLoading, setIsLoading] = useState(false);
  const [userMail, setUserMail] = useState('');
  const auth = useAuth();
  const [error, setError] = useState();
  const [specialsitterUserMail, setSpecialsitterUserMail] = useState("");
  const [seeWelcome, setSeeWelcome ] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [loginSpecialsitterVisible, setLoginSpecialsitterVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toggleLoginSpecialsitterVisibility = () => {
    setLoginSpecialsitterVisible(!loginSpecialsitterVisible);
  }
  
  const handleSubmit = async (userInfo) => {
    setIsLoading(true);
    setUserMail(userInfo.userMail);
    try {
      // Überprüfen der E-Mail-Adresse
      const checkResponse = await axios.get(`${baseURL}/users/checkUserMail/${userInfo.userMail}`);
      if (checkResponse.data.exists && checkResponse.data.deleted) {
          Alert.alert(
              "Konto wiederherstellen",
              "Ein Konto mit dieser E-Mail existiert bereits. Möchtest du dieses Konto wiederherstellen?",
              [
                  { text: "Ja", onPress: () => restoreAccount(userMail) },
                  { text: "Nein", onPress: () => console.log("Nicht wiederherstellen") }
              ]
          );
      } else { 
        // Überprüfen den userProfileName
        const checkResponse = await axios.get(`${baseURL}/users/checkUserProfileName/${userInfo.userProfileName}`);
        if (checkResponse.data.exists && checkResponse.data.deleted) {
            Alert.alert(
                "Der Profilename existiert bereits.",
                "Versuche es nochmal. Füge vielleicht Zahlen oder andere Zeichen hinzu, um dein Profilname einzigartig zu machen.",
                [
                    { text: "Ja", onPress: () => restoreAccount(userMail) },
                    { text: "Nein", onPress: () => console.log("Nicht wiederherstellen") }
                ]
            );
        } else { 
          const startTime = new Date().getTime();
          // Axios POST-Anfrage für die Registrierung
          const registerResponse = await axios.post('${baseURL}/users/register', userInfo);

          // Registrierung erfolgreich, jetzt Login durchführen
          if (registerResponse.status === 201 || registerResponse.status === 200) {
            const loginResponse = await axios.post('${baseURL}/login', {
              userMail: userInfo.userMail,
              password: userInfo.password
            });
            // Login erfolgreich, accessToken speichern und zum Benutzer-Dashboard weiterleiten
            const accessToken = loginResponse.data.accessToken;
            // Berechnen, wie lange die Anmeldung schon angezeigt wurde
            const endTime = new Date().getTime();
            const timeDiff = endTime - startTime;
      
            // Berechnen Sie den zusätzlichen Delay, um sicherzustellen, dass die Animation mindestens 3 Sekunden angezeigt wird
            const additionalDelay = Math.max(3000 - timeDiff, 0);
            // Stellen Sie sicher, dass der Loader für insgesamt mindestens 3 Sekunden angezeigt wird
            setTimeout(() => {
              auth.logIn(accessToken);
              setIsLoading(false); // Loader deaktivieren
              setError(null); // Fehler zurücksetzen
            }, additionalDelay);
          }
        }
      }
    } catch (error) {
      setIsLoading(false); // Loader deaktivieren
      if (error.response) {
          // Der Server hat eine Antwort mit einem Fehlerstatus gesendet
          console.error('Fehler beim Login:', error.response);
          setError(error.response.data.error || error.response.statusText || 'Ein Fehler ist aufgetreten.');
      } else if (error.request) {
          // Die Anfrage wurde gesendet, aber keine Antwort erhalten
          console.error('Keine Antwort vom Server:', error.request);
          setError('Keine Antwort vom Server.');
      } else {
          // Etwas anderes hat den Fehler verursacht
          console.error('Login-Fehler:', error.message);
          setError('Ein Fehler ist aufgetreten.');
      }
    }
  };

  const restoreAccount = async (userMail) => {
    setIsLoading(true);
    try {
        await axios.post(`${baseURL}/users/restoreAccount/${userMail}`);
        Alert.alert(
            "E-Mail gesendet",
            "Eine E-Mail mit einem temporären Passwort wurde versendet mit dem du dich einloggen kannst.",
            [{ text: "OK", onPress: () => navigation.navigate('Einloggen') }]
        );
        setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
        // Fehlerbehandlung
        // ...
    }
  };

  const handleLoginSpecialsitter = async (formValues) => {
    setIsLoading(true);
    try {
      email=formValues.userMail;
      password=formValues.password;
      userProfileName=formValues.userProfileName;
      const loginSpecialsitter = await axios.post(`${baseURL}/specialsitter/checkSpecialsitterUser`,{email, password, userProfileName});

      if (loginSpecialsitter.status === 200) {
        Alert.alert("Super!", "Das hat funktioniert. Deine Login Daten wurden erfolgreich für das Forum übernommen. Sie werden künftig aber nicht mehr synchsonisiert.");
        const loginResponse = await axios.post('${baseURL}/login', {
          userMail: formValues.userMail,
          password: formValues.password
        });
        const startTime = new Date().getTime();
        // Login erfolgreich, accessToken speichern und zum Benutzer-Dashboard weiterleiten
        const accessToken = loginResponse.data.accessToken;
        // Berechnen, wie lange die Anmeldung schon angezeigt wurde
        const endTime = new Date().getTime();
        const timeDiff = endTime - startTime;
  
        // Berechnen Sie den zusätzlichen Delay, um sicherzustellen, dass die Animation mindestens 3 Sekunden angezeigt wird
        const additionalDelay = Math.max(3000 - timeDiff, 0);
        // Stellen Sie sicher, dass der Loader für insgesamt mindestens 3 Sekunden angezeigt wird
        setTimeout(() => {
          auth.logIn(accessToken);
          setIsLoading(false); // Loader deaktivieren
          setError(null); // Fehler zurücksetzen
        }, additionalDelay);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        Alert.alert("Fehler", "Du konntest leider nicht automatisch eingeloggt werden! Versuche es manuel.",
          [{ text: "OK", onPress: () => navigation.navigate('Einloggen') }]
        );
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Fehler:", error);
      if (error.response) {
          const status = error.response.status;
          if (status === 404) {
              Alert.alert("Fehler", "Das angegebene Konto wurde nicht gefunden. Bitte überprüfen Sie die E-Mail-Adresse.");
          } else if (status === 400) {
              Alert.alert("Fehler", "Die eingegebenen Anmeldedaten sind nicht korrekt. Bitte überprüfen Sie Ihre E-Mail und Ihr Passwort.");
          } else if (status === 500) {
              Alert.alert("Fehler", "Es gab ein Problem bei der Verbindung zum Server. Bitte versuchen Sie es später erneut.");
          } else {
              Alert.alert("Fehler", "Ein unerwarteter Fehler ist aufgetreten");
          }
      } else {
          Alert.alert("Fehler", "Ein unerwarteter Fehler ist aufgetreten");
      }
  }
  }

  return (
    <>
      <ActivityIndicator visible={isLoading} />
      <Screen style={styles.container}>
        {!loginSpecialsitterVisible && (
          <Form
            initialValues={{ userProfileName: "", userMail: "", password: "", userFirstName: "", userLastName: "" }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <ErrorMessage error="Bei der Registrierung ist ein Fehler aufgetreten." visible={error} />
            <FormField
              autoCorrect={false}
              icon="account"
              name="userProfileName"
              placeholder="Profilname"
            />
            <FormField
              autoCorrect={false}
              icon="account"
              name="userFirstName"
              placeholder="Vorname"
            />
            <FormField
              autoCorrect={false}
              icon="account"
              name="userLastName"
              placeholder="Nachname"
            />
            <FormField
              autoCapitalize="none"
              autoCorrect={false}
              icon="email"
              keyboardType="email-address"
              name="userMail"
              placeholder="Email"
              textContentType="emailAddress"
            />
            <FormField
              autoCapitalize="none"
              autoCorrect={false}
              icon="lock"
              name="password"
              placeholder="Password"
              secureTextEntry
              textContentType="password"
            />
            <SubmitButton title="Register" />
          </Form>
        )}
        {loginSpecialsitterVisible && (
          <>
            <Form
              initialValues={{ userMail: "", password: "" }}
              onSubmit={handleLoginSpecialsitter}
              validationSchema={validationSchema}
            >
              <ErrorMessage error='Passwort und/oder Email ist falsch.' visible={loginFailed} />
              <FormField
                autoCorrect={false}
                icon="account"
                name="userProfileName"
                placeholder="Profilname"
              />
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                icon="email"
                keyboardType="email-address"
                name="userMail"
                placeholder="Email"
                textContentType="emailAddress"
              />
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                icon="lock"
                name="password"
                placeholder="Passwort"
                secureTextEntry={!showPassword}
                textContentType="password"
              />

              <TouchableOpacity
                style={styles.eyeIcon}
                onPress={() => setShowPassword(!showPassword)}
              >
                <Text><FontAwesome name={showPassword ? 'eye' : 'eye-slash'} size={24} color="black" />{showPassword?"  Passwörter verbergen" : "  Passwörter zeigen"}</Text>
              </TouchableOpacity>
              <SubmitButton title="Einloggen" />
            </Form>
            <Text style={styles.loginSpecialsitterText}>
              Wenn du einen Account bei Specialsitter hast, dann übernehmen wir einmalig deine Login Daten für dieses Forum. Gib aber unbedingt noch einen Profilnamen an.
            </Text>
          </>
        )}
        <TouchableOpacity
          style={styles.accountSpecialsitter}
          onPress={toggleLoginSpecialsitterVisibility}
        >
          <Text>
            <MaterialCommunityIcons name={loginSpecialsitterVisible?"account-cancel":"account-check"} size={24} color={loginSpecialsitterVisible?colors.warning:colors.success} />
            {!loginSpecialsitterVisible ? "Du hast ein Specialsitter Account?" : "Ich habe doch kein Specialsitter Account!"}
          </Text>
        </TouchableOpacity>
      </Screen>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  accountSpecialsitter: {
    marginTop: 10,
    marginBottom: 10,
    alignItems: 'center',
  },
  loginSpecialsitterText: {
    marginTop: 10,
    textAlign: 'center',
  },
  loginSpecialsitterButton: {
    marginTop: 10,
    backgroundColor: colors.primary,
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
  },
  loginSpecialsitterTextButton: {
    color: colors.white,
    paddingLeft: 5
  }
});

export default RegisterScreen;
