import React, {useEffect, useState} from "react";
import { NavigationContainer } from "@react-navigation/native";
import * as SplashScreen from 'expo-splash-screen';
import axios from "axios";


import { baseURL } from "./app/api/client";
import navigationTheme from "./app/navigation/navigationTheme";
import AppNavigator from "./app/navigation/AppNavigator";
import OfflineNotice from "./app/components/OfflineNotice";
import AuthNavigator from "./app/navigation/AuthNavigator";
import AuthContext from "./app/auth/context";
import authStorage from "./app/auth/storage";
import { navigationRef } from "./app/navigation/rootNavigation";


export default function App() {
  const [user, setUser] = useState();
  const [isReady, setIsReady] =useState(false);

  useEffect(() => {
    const restoreUser = async () => {
      try {
        await SplashScreen.preventAutoHideAsync();
        const user = await authStorage.getUser();
        if (user) {
          try {
            await axios.get(`${baseURL}/users/checkUser/${user.userID}`);
            setUser(user);
          } catch (error) {
            // console.error("Der User exisitiert nicht mehr in der Datenbank:", error);
            // Nutzer existiert nicht mehr in der Datenbank, also setzen wir user auf null
            setUser(null);
            await authStorage.removeToken();
          }
        }
      } catch (e) {
        console.warn(e);
      } finally {
        setIsReady(true);
        await SplashScreen.hideAsync();
      }
    };

  restoreUser();
}, []);


  if (!isReady) return null;

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <OfflineNotice />
        <NavigationContainer ref={navigationRef} theme={navigationTheme}>
            {user ? <AppNavigator /> : <AuthNavigator />}
        </NavigationContainer>
    </AuthContext.Provider>
  );
}
