export default Object.freeze({
  LISTING_DETAILS: "ListingDetails",
  LISTING_EDIT: "ListingEdit",
  LOGIN: "Einloggen",
  MESSAGES: "Messages",
  REGISTER: "Registrieren",
  VALIDATION: "Validation",
  FORUM: "Forum",
  HOME: "Home",
  DATEN: "Daten",
  SETTINGS: "Einstellungen",
  AUTHORIZATIONS: "Benachrichtigungen",
  DATAEDITSCREEN: "Daten bearbeiten",
  EDITPASSWORDSCREEN: "Passwort ändern",
  ACCOUNTDETAIL: "Konto",
  LEGALS: "Rechtliches",
  SUPPORT: "Support",
  OTHERSTHREADSSCREEN: "Sonstiges",
  DISEASESSCREEN: "Krankheiten",
  THREADSSCREEN: "Themen",
  THREADSCREEN: "Thema",
  ADDTHREADSCREEN: "Thema erstellen",
  PatchTHREADSCREEN: "Thema bearbeiten",
  ADDFORUMSUGGESTIONSCREEN: "Vorschlag einreichen",
  //knowledge
  KNOWLEDGESCREEN: "Wissen to Go",
  ABOUTSPECIALSITTERSCREEN: "Über Specialsitter",
  ACADEMYSCREEN: "Academy",
  TIPSSCREEN: "Tips",
  TYPESOFBENEFITSSCREEN: "Die Leistungsarten",
  //networking
  NETWORKINGSCREEN: "Vernetzung mit anderen Familien",
  NETWORKINGDETAILSCREEN: "Vernetzung in ???",
  HOLIDAYINFOSCREEN: "Ausflug- und Urlaubstips",
  HOLIDAYGERMANYSCREEN: "Deutschland",
  HOLIDAYWORLDSCREEN: "Europa und Weltweit",
  HOLIDAYCONTENTSCREEN: "Allgemeine Infos / Erfahrungsberichte",
  HOLIDAYDETAILSCREEN: "Details",
});
