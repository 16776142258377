import React, { useState, useEffect } from "react";
import { StyleSheet, TouchableWithoutFeedback, Keyboard, KeyboardAvoidingView, Platform } from "react-native";
import DropDownPicker from 'react-native-dropdown-picker';
import { Formik } from 'formik';

import {
  FormField,
  SubmitButton,
} from "../../components/forms";
import colors from "../../config/colors";
import Screen from "../../components/Screen";
import useAuth from "../../auth/useAuth";

function SupportScreen() {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [items, setItems] = useState([
    { label: 'Forum', value: 'Forum' },
    { label: 'Wissen to Go', value: 'Wissen to Go' },
    { label: 'Vernetzung mit anderen Familien', value: 'Vernetzung mit anderen Familien' },
    { label: 'Ausflug- und Urlaubstips', value: 'Ausflug- und Urlaubstips' },
    { label: 'Technisches', value: 'Technisches' },
    { label: 'Account', value: 'Account' },
  ]);

  useEffect(() => {
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        console.log('Keyboard did hide');
      }
    );

    return () => {
      keyboardDidHideListener.remove();
    };
  }, []);

  const handleSubmit = async (userInfo, { resetForm }) => {
    resetForm();
  };

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 0}
    >
      <TouchableWithoutFeedback  onPress={Keyboard.dismiss} accessible={false} >
        <Screen style={styles.container}>
          <Formik
            initialValues={{
              name: user.name,
              email: user.email,
            }}
            onSubmit={handleSubmit}
            // validationSchema={validationSchema}
          >
              <>
                <FormField maxLength={255} name="name" placeholder="Name" width="100%" />
                <FormField maxLength={255} name="email" placeholder="Email" keyboardType="email-address" autoCapitalize="none" />
                <DropDownPicker
                  open={open}
                  value={value}
                  items={items}
                  setOpen={setOpen}
                  setValue={setValue}
                  setItems={setItems}
                  placeholder="bitte wählen"
                />
                <FormField
                  name="message"
                  placeholder="Deine Nachricht"
                  multiline
                  numberOfLines={6}  // Setzen Sie die gewünschte Zeilenanzahl
                  style={{ height: 120 }} // Setzen Sie die gewünschte Zeilenanzahl
                />
                <SubmitButton title="Senden" color={colors.success} />
              </>
          </Formik>
        </Screen>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  picker: {
    borderWidth: 1,
    borderColor: colors.medium,
    borderRadius: 4,
  },
});

export default SupportScreen;
