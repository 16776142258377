import React from "react";
import { StyleSheet, View, TouchableOpacity, Image, Linking } from "react-native";

import Text from "./Text";
import colors from "../config/colors";

function HolidayContentCard({ link, title, description, image }) {
  return (
    <View style={styles.card}>
        <Image source={image} style={styles.image} />
        <View style={styles.textContainer}>
            <Text style={styles.title}>{title}</Text>
            <Text numberOfLines={8} style={styles.description}>{description}</Text>
            <View style={styles.buttonContainer}>
                <TouchableOpacity onPress={() => Linking.openURL(link)} style={styles.linkButton}>
                    <Text style={styles.linkText}>Zur Seite</Text>
                </TouchableOpacity>
            </View>
        </View>
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    flexDirection: "row",
    backgroundColor: "#fff",
    marginVertical: 10,
    borderRadius: 10,
    overflow: "hidden",
  },
  image: {
    width: "25%",
    height: 175,
  },
  textContainer: {
    padding: 10,
    width: "75%",
  },
  title: {
    fontWeight: "bold",
    marginBottom: 5,
  },
  linkText: {
    color: colors.primary,
    fontSize: 14,
  },
  button: {
    borderWidth: 1,
    borderColor: colors.medium,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    paddingHorizontal: 10,
  },
  buttonText: {
    color: colors.dark,
    fontSize: 14,
  },
  linkButton: {
    borderWidth: 1,
    borderColor: colors.primary,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    paddingHorizontal: 10,
  },
  description: {
    fontSize: 12,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    },
});

export default HolidayContentCard;
