import React, { useState } from "react";
import { FontAwesome } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { StyleSheet, Image, Alert, TouchableOpacity } from "react-native";
import * as Yup from "yup";
import axios from 'axios'; // Stellen Sie sicher, dass Axios installiert ist

import { baseURL } from "../api/client";
import Screen from "../components/Screen";
import WelcomeModal from "./WelcomeModal";
import { ErrorMessage, Form, FormField, SubmitButton } from "../components/forms";
import useAuth from "../auth/useAuth";
import Text from "../components/Text";
import ButtonBig from "../components/ButtonBig";
import colors from "../config/colors";
import ActivityIndicator from "../components/ActivityIndicator";
import TextInput from "../components/forms/TextInput";


const validationSchema = Yup.object().shape({
  userMail: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(4).label("Passwort"),
});

function LoginScreen() {
  const auth = useAuth();
  const [userMail, setUserMail] = useState("");
  const [seeWelcome, setSeeWelcome ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toggleResetPasswordVisibility = () => {
    setResetPasswordVisible(!resetPasswordVisible);
  }

  const handleSubmit = async ({ userMail, password }) => {
    setIsLoading(true);
    try {
      const startTime = new Date().getTime();
      const response = await axios.post(`${baseURL}/login`, { userMail, password }, {
        headers: {
          'Content-Type': 'application/json',
        },
        transformRequest: (data) => {
          return JSON.stringify(data); // Konvertieren Sie die Daten explizit in einen JSON-String
        },
      });
      const accessToken = response.data.accessToken;
      // Berechnen, wie lange die Anmeldung schon angezeigt wurde
      const endTime = new Date().getTime();
      const timeDiff = endTime - startTime;

      // Berechnen Sie den zusätzlichen Delay, um sicherzustellen, dass die Animation mindestens 3 Sekunden angezeigt wird
      const additionalDelay = Math.max(3000 - timeDiff, 0);

      // Stellen Sie sicher, dass der Loader für insgesamt mindestens 3 Sekunden angezeigt wird
      setTimeout(() => {
        setIsLoading(false);
        auth.logIn(accessToken);
        setLoginFailed(false);
        setSeeWelcome(true);
      }, additionalDelay);
    } catch (error) {
      setLoginFailed(true);
      console.log('Ein Fehler ist aufgetreten: ', error);
      // Auch hier könnten Sie eine Verzögerung hinzufügen, wenn Sie möchten
      setIsLoading(false);
    }
  };
  
  const handleResetPassword = async () => {
    try {
      const resetPassword = await axios.post(`${baseURL}/users/resetPassword/${userMail}`);

      if (resetPassword.status === 200) {
        Alert.alert("E-Mail gesendet", "Bitte überprüfe deinenp E-Mail für das temporäre Passwort.", [
          {
            text: "OK",
          }
        ]);
        setResetPasswordVisible(false);
        setUserMail('');
      } else {
        Alert.alert("Fehler", "Das Passwort konnte nicht aktualisiert werden!");
      }
    } catch (error) {
      console.error("Fehler beim Senden der Email:", error);
      Alert.alert("Fehler", "Ein unerwarteter Fehler ist aufgetreten");
    }
  }

  return (
    <Screen style={styles.container}>
      <ActivityIndicator visible={isLoading} />
      <WelcomeModal visible={seeWelcome} onClose={() => setSeeWelcome(false)} />
      <Image style={styles.logo} source={require("../assets/illustrations/hw-large.webp")} />
      {!resetPasswordVisible && (
        <Form
          initialValues={{ userMail: "", password: "" }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <ErrorMessage error='Passwort und/oder Email ist falsch.' visible={loginFailed} />
          <FormField
            autoCapitalize="none"
            autoCorrect={false}
            icon="email"
            keyboardType="email-address"
            name="userMail"
            placeholder="Email"
            textContentType="emailAddress"
          />
          <FormField
            autoCapitalize="none"
            autoCorrect={false}
            icon="lock"
            name="password"
            placeholder="Passwort"
            secureTextEntry={!showPassword}
            textContentType="password"
          />

          <TouchableOpacity
            style={styles.eyeIcon}
            onPress={() => setShowPassword(!showPassword)}
          >
            <Text><FontAwesome name={showPassword ? 'eye' : 'eye-slash'} size={24} color="black" />{showPassword?"  Passwörter verbergen" : "  Passwörter zeigen"}</Text>
          </TouchableOpacity>
          <SubmitButton title="Einloggen" />
        </Form>
      )}
      {resetPasswordVisible && (
        <>
          <TextInput
            autoCapitalize="none"
            onChangeText={(text) => setUserMail(text)}
            value={userMail}
            placeholder="deine Email..."
          />
          <Text style={styles.resetPasswordText}>
            Wenn du dein Passwort vergessen hast, kannst du dir ein temporäres Passwort per E-Mail anfordern.
          </Text>
          <ButtonBig
            style={styles.resetPasswordButton}
            onPress={handleResetPassword}
          >
            <MaterialCommunityIcons name="send" size={24} color={colors.white} />
            <Text style={styles.resetPasswordTextButton}>Passwort zurücksetzen</Text>
          </ButtonBig>
        </>
      )}
      <TouchableOpacity
        style={styles.forgotPassword}
        onPress={toggleResetPasswordVisibility}
      >
        <Text>
          <MaterialCommunityIcons name={resetPasswordVisible?"head-lightbulb":"head-question"} size={24} color={resetPasswordVisible?colors.success:colors.warning} />
          {!resetPasswordVisible ? "Passwort vergessen?" : "Doch nicht vergessen!"}
        </Text>
      </TouchableOpacity>
      
    </Screen>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  logo: {
    width: 80,
    height: 80,
    alignSelf: "center",
    marginTop: 50,
    marginBottom: 20,
  },
  forgotPassword: {
    marginTop: 10,
    marginBottom: 10,
    alignItems: 'center',
  },
  resetPasswordText: {
    marginTop: 10,
    textAlign: 'center',
  },
  resetPasswordButton: {
    marginTop: 10,
    backgroundColor: colors.primary,
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
  },
  resetPasswordTextButton: {
    color: colors.white,
    paddingLeft: 5
  }
});

export default LoginScreen;
