import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import AccountScreen from "../screens/accountscreens/AccountScreen";
import DataScreen from "../screens/accountscreens/DataScreen";
import EditDataScreen from "../screens/accountscreens/EditDataScreen";
import AuthorizationsScreen from "../screens/accountscreens/AuthorizationsScreen";
import SupportScreen from "../screens/accountscreens/SupportScreen";
import LegalsScreen from "../screens/accountscreens/LegalsScreen";
import AccountDetail from "../screens/accountscreens/AccountDetail";
import EditPasswordScreen from "../screens/accountscreens/EditPasswordScreen";

const Stack = createStackNavigator();

const AccountNavigator = () => (
  <Stack.Navigator>
    <Stack.Screen 
      name="AccountScreen" 
      component={AccountScreen} 
      options={{ 
        headerShown: false, 
        title: 'Account'  // Definiert den Titel für den Zurück-Button auf anderen Seiten
      }}  
    />
    <Stack.Screen name="Daten" component={DataScreen} />
    <Stack.Screen name="Daten bearbeiten" component={EditDataScreen} />
    <Stack.Screen name="Passwort ändern" component={EditPasswordScreen} />
    <Stack.Screen name="Benachrichtigungen" component={AuthorizationsScreen} />
    <Stack.Screen name="Konto" component={AccountDetail} />
    <Stack.Screen name="Rechtliches" component={LegalsScreen} />
    <Stack.Screen name="Support" component={SupportScreen} />
  </Stack.Navigator>
);

export default AccountNavigator;
