import React, { useState, useEffect } from 'react';
import { View, TextInput, StyleSheet, TouchableOpacity, FlatList, ActivityIndicator, Platform } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import axios from 'axios';

import { baseURL } from "../../api/client";
import colors from "../../config/colors";
import Icon from "../../components/Icon";
import Screen from "../../components/Screen";
import Text from '../../components/Text';
import routes from "../../navigation/routes";
import ListItem from "../../components/lists/ListItem";
import AddForumSuggestionScreen from "./AddForumSuggestionScreen";


function ForumScreen({ navigation }) {
  const [forumData, setForumData] = useState([]);
  const [forumDiseasesCount, setForumDiseasesCount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  const [filteredThreads, setFilteredThreads] = useState([]);

  useEffect(() => {
    if (searchText) {
      searchThreads();
    }
  }, [searchText]); 

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };
  // Funktion, um alle Foren zu holen
  useEffect(() => {
    const fetchForumData = async () => {
      setIsLoading(true);
      try {
        const forumsResponse = await axios.get(`${baseURL}/forums/getForums`);
        const forums = forumsResponse.data;
  
        // Für jedes Forum die Anzahl der Threads abrufen
        for (const forum of forums) {
          const threadsResponse = await axios.get(`${baseURL}/threads/countThreads/${forum.forumID}`);
          forum.threadsCount = threadsResponse.data.count;
        }
   
        setForumData(forums);
      } catch (error) {
        console.error("Fehler beim Abrufen der Forumdaten:", error);
      }
      setIsLoading(false);
    };
    const countForumDiseases = async () => {
      try {
        const response = await axios.get(`${baseURL}/forums/countForumsDiseases`);
        setForumDiseasesCount(response.data.count);
      } catch (error) {
        console.error("Fehler beim Abrufen der Forumdaten:", error);
      }
    };
  
    fetchForumData();
    countForumDiseases();
  }, []);
  
  const searchThreads = async () => {
    if (!searchText) return;
  
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseURL}/threads/searchThreads`, {
        params: { query: searchText }
      });
      setFilteredThreads(response.data);
    } catch (error) {
      console.error("Fehler bei der Suche: ", error);
    }
    setIsLoading(false);
  };


  return ( 
    <Screen style={styles.screen}>
      <View style={styles.search}>
        {!isSearchVisible && <View style={{flex: 1}} />} 
        {isSearchVisible && (
          <View style={styles.searchInputContainer}>
            <TextInput
              style={styles.searchInput}
              value={searchText}
              onChangeText={setSearchText}
              placeholder="Suche..."
            />
            <MaterialCommunityIcons
              name="close"
              color={colors.dark}
              size={25}
              onPress={() => {
                setIsSearchVisible(false);  // Versteckt das Suchfeld und löscht den Text, wenn auf das "X" geklickt wird
                setSearchText("");
              }}
            />
          </View>
        )}
        <MaterialCommunityIcons
          style={styles.searchIcon}
          name="magnify"
          color={colors.dark}
          size={25}
          onPress={() => setIsSearchVisible(true)}  // Zeigt das Suchfeld an, wenn auf das Symbol geklickt wird
        />
      </View>
      {isLoading ? (
        // Zeige den Loader an, wenn Daten geladen werden
        <View style={styles.loaderContainer}>
          <ActivityIndicator size="large" color={colors.primary} />
        </View>
      ) : (
        <>
          {isSearchVisible && searchText && !isLoading ? (
            filteredThreads.length > 0 ? (
              <FlatList
                data={filteredThreads}
                keyExtractor={thread => thread.threadID.toString()}
                renderItem={({ item }) => (
                  <ListItem
                    title={item.threadTitle}
                    subTitle={item.threadPreview}
                    onPress={() => navigation.navigate('Thema', { title: item.threadTitle, threadID: item.threadID })}
                  />
                )}
              />
            ) : (
              <Text>Keine Ergebnisse gefunden.</Text>
            )
          ) : (
            <>
              <ListItem
                title="Themen nach Krankheiten / Krankheitsbildern"
                subTitle={forumDiseasesCount ? `${forumDiseasesCount} Unterforen` : "Noch kein Unterforum erstellt"}
                subTitleStyle={styles.subTitleText}
                IconComponent={<Icon name="arrow-right" backgroundColor={colors.info} />}
                onPress={() => navigation.navigate(routes.DISEASESSCREEN)}
              />
              <FlatList
                data={forumData}
                keyExtractor={forum => forum.forumID.toString()}
                renderItem={({ item }) => (
                  <ListItem
                    title={item.forumTitle}
                    subTitle={item.threadsCount ? `${item.threadsCount} Themen` : "Noch kein Thema erstellt"}
                    subTitleStyle={styles.subTitleText} 
                    IconComponent={<Icon name="arrow-right" backgroundColor={colors.info} />}
                    onPress={() => navigation.navigate('Themen', { forumTitle: item.forumTitle, forumID: item.forumID })}
                  />
                )}
              />
            </>
          )}
        </>
      )}
      <TouchableOpacity style={styles.fab} onPress={toggleModal} >
        <MaterialCommunityIcons name="lightbulb-on-outline" color={colors.white} size={26} />
      </TouchableOpacity>
      <AddForumSuggestionScreen isVisible={isModalVisible} onClose={toggleModal} />
    </Screen>
  );
}

const styles = StyleSheet.create({
  screen: {
    backgroundColor: colors.light,
  },
  container: {
    marginVertical: 20,
  },
  containerItem: {
    alignItems: "center",
    flexDirection: "row",
    padding: 15,
    backgroundColor: colors.white,
  },
  detailsContainer: {
    flex: 1,
    marginLeft: 10,
    justifyContent: "center",
  },
  image: {
    width: 70,
    height: 70,
    borderRadius: 35,
  },
  subTitleText: {
    fontSize: 14,
    color: colors.success,
  },
  title: {
    fontWeight: "500",
  },
  search: {
    flexDirection: "row",
    justifyContent: "space-between",  // Stellt sicher, dass das Suchsymbol immer rechts ist
    alignItems: "center",
    paddingHorizontal: 10,
    paddingTop: Platform.OS === 'android' ? 0 : 15,
    paddingBottom: 20,
  },
  searchInputContainer: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    borderWidth: 1,
    borderColor: colors.dark,
    borderRadius: 5,
    paddingHorizontal: 10,
  },
  searchInput: {
    flex: 1,
    paddingRight: 100,
  },
  searchIcon: {
    paddingLeft: 10,
  },
  fab: {
    position: 'absolute',
    left: 10,
    bottom: 10,
    backgroundColor: colors.warning,
    borderRadius: 50,
    width: 56,
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ForumScreen;