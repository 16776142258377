import React from "react";
import { View, StyleSheet, Modal, ScrollView } from "react-native";
import axios from "axios";

import Text from '../components/Text';
import ButtonBig from '../components/ButtonBig';
import useAuth from "../auth/useAuth";
import { baseURL } from "../api/client";

function WelcomeModal({ visible, onClose }) {
  const { user } = useAuth();
  
  const handleClose = async () => {
    // Schließen des Modals und setzen von seeWelcome auf 1 im Backend
    await axios.post(`${baseURL}/users/patchSeeWelcome/${user.userID}`);
    onClose();
  };

  return (
    <Modal visible={visible} onRequestClose={handleClose}>
      <View style={styles.container}>
        <ScrollView>
          <Text style={styles.headline}>Herzlich Willkommen in unserer Gemeinschaft!</Text>
          <Text style={styles.body}>Wir freuen uns riesig, dich in unserer besonderen Gemeinschaft willkommen zu heißen. Diese Plattform ist ein sicherer Raum für Familien und Angehörige von Menschen mit Behinderungen oder Beeinträchtigungen. Hier kannst du Erfahrungen austauschen, Unterstützung finden und wertvolle Informationen teilen.</Text>
          <Text style={styles.body}>Du bist jetzt Teil einer Gemeinschaft, die Verständnis und Empathie großschreibt. Wir wissen, dass das Leben mit einer Behinderung oder Beeinträchtigung in der Familie Herausforderungen mit sich bringt, aber auch mit einzigartigen Momenten der Freude und des Triumphes verbunden sein kann. Hier kannst du sowohl deine Sorgen als auch deine Erfolgserlebnisse teilen.</Text>
          <Text style={styles.body}>Nutze die Gelegenheit, dich mit anderen Mitgliedern auszutauschen, an Diskussionen teilzunehmen und von den Erfahrungen anderer zu lernen. Deine Geschichte, deine Fragen und dein Wissen sind hier willkommen und wertvoll.</Text>
          <Text style={styles.body}>Wir ermutigen dich, dich aktiv zu beteiligen, Fragen zu stellen und deine Erfahrungen zu teilen. Gemeinsam können wir eine starke, unterstützende und informierte Gemeinschaft aufbauen.</Text>
          <Text style={styles.body}>Willkommen bei uns – ein Ort des Verständnisses, der Unterstützung und der Hoffnung.</Text>
          <Text style={styles.greeting}>Mit herzlichen Grüßen,</Text>
          <Text style={styles.greeting}>Dein Specialsitter Team</Text>
        </ScrollView>
        <ButtonBig title="Zum Forum" onPress={handleClose} />
      </View> 
    </Modal>
  );
}

const styles = StyleSheet.create({
  animation: {
    flex: 1,
    width: 150,  // Stellen Sie sicher, dass diese Werte korrekt sind
    height: 150,  // Fügen Sie eine Höhe hinzu, falls benötigt
  },
  container: {
    flex: 1,
    justifyContent: "space-between", // Sorgt dafür, dass der Button am unteren Rand bleibt
    margin: 30,
    paddingTop: 50
  },
  headline: {
    fontSize: 20,
    fontWeight: "bold"
  },
  body: {
    fontSize: 14,
    marginTop:20
  },
  greeting: {
    fontSize: 14,
    marginTop: 30
  }
});

export default WelcomeModal;
